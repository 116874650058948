import { getAddresses, Networks } from "src/constants";

const mntPerBlock = 20;
export const mntPerYear = mntPerBlock * 43200 * 365;

export function getTokenAddress(networkID: Networks, token: string): string {
    const addresses = getAddresses(networkID);
    if (token === "usdc") return addresses.USDC_ADDRESS;
    else if (token === "usdt") return addresses.USDT_ADDRESS;
    else if (token === "dai") return addresses.DAI_ADDRESS;
    else return "";
}

export function getLpTokenAddress(networkID: Networks, token: string): string {
    const addresses = getAddresses(networkID);
    if (token === "usdc") return addresses.ASSET_USDC_ADDRESS;
    else if (token === "usdt") return addresses.ASSET_USDT_ADDRESS;
    else if (token === "dai") return addresses.ASSET_DAI_ADDRESS;
    else return "";
}

export function getTokenPid(token: string): Number {
    if (token === "usdc") return 0;
    else if (token === "usdt") return 1;
    else if (token === "dai") return 2;
    else return -1;
}

export function getTokenFormatUnit(token: string): string {
    if (token == "usdc" || token == "usdt") return "mwei";
    else return "ether";
}
