import "./swap.scss";
import { ethers } from "ethers";
import { useWeb3Context } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "src/store/slices/state.interface";
import { changeApproval, swap } from "src/store/slices/pool-thunk";
import { useState, useCallback, useEffect } from "react";
import { Grid, OutlinedInput, Select, Zoom, Box, MenuItem, InputAdornment } from "@material-ui/core";
import { formatValue, getTokenSVG, trim } from "../../helpers";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { Skeleton } from "@material-ui/lab";
import { warning } from "../../store/slices/messages-slice";
import { getAddresses } from "src/constants";
import { PoolContract } from "src/abi";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import SwapIcon from "../../assets/icons/arrow-swap.svg";
import SettingIcon from "../../assets/icons/settings.svg";
import InfoIcon from "../../assets/icons/info-circle.svg";
import USDCIcon from "../../assets/tokens/USDC.svg";
import USDTIcon from "../../assets/tokens/USDT.svg";
import DAIIcon from "../../assets/tokens/DAI.svg";
import CloseIcon from "../../assets/icons/circle-xmark.svg";
import Modal from "@material-ui/core/Modal";
import { getLpTokenAddress, getTokenFormatUnit } from "src/helpers/get-token-data";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";

function Swap() {
    const dispatch = useDispatch();
    const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();
    const addresses = getAddresses(chainID);

    const [quantityFrom, setQuantityFrom] = useState<string>("");
    const [quantityTo, setQuantityTo] = useState<string>("");
    const [tokenFrom, setTokenFrom] = useState<string>("");
    const [tokenTo, setTokenTo] = useState<string>("");
    // const [fromBalance, setFromBalance] = useState<string>("");
    // const [toBalance, setToBalance] = useState<string>("");
    const [haircut, setHaircut] = useState<string>("");

    const [rate, setRate] = useState<string>("");
    const [priceImpact, setPriceImpact] = useState<string>("");

    const [slippage, setSlippage] = useState<string>(localStorage.getItem("swap_slippage") || "0.1");
    const [deadline, setDeadline] = useState<string>(localStorage.getItem("swap_deadline") || "5");

    const [approveBtnDisabled, setApproveBtnDisabled] = useState<boolean>(true);
    const [swapBtnDisabled, setSwapBtnDisabled] = useState<boolean>(true);

    const [open, setOpen] = useState<boolean>(false);
    const [settingOpen, setSettingOpen] = useState<boolean>(false);

    const poolContract = new ethers.Contract(addresses.POOL_ADDRESS, PoolContract, provider);

    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
    const usdcBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.usdc;
    });
    const usdtBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.usdt;
    });
    const daiBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.dai;
    });
    const usdcAllowance = useSelector<IReduxState, number>(state => {
        return state.account.allowance_pool && state.account.allowance_pool.usdc;
    });
    const usdtAllowance = useSelector<IReduxState, number>(state => {
        return state.account.allowance_pool && state.account.allowance_pool.usdt;
    });
    const daiAllowance = useSelector<IReduxState, number>(state => {
        return state.account.allowance_pool && state.account.allowance_pool.dai;
    });
    const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
        return state.pendingTransactions;
    });

    const getTokenBalance = (token: string): string => {
        if (token == "usdc") return usdcBalance;
        else if (token == "usdt") return usdtBalance;
        else if (token == "dai") return daiBalance;
        return "";
    };

    const setMax = () => {
        setQuantityFrom(getTokenBalance(tokenFrom));
    };

    const setPositiveSlippage = (value: string) => {
        if (Number(value) >= 0 && Number(value) <= 100) {
            setSlippage(value);
            localStorage.setItem("swap_slippage", value);
        } else {
            setSlippage("0.1");
        }
    };

    const setLimitedDeadline = (value: string) => {
        if (Number(value) > 0 && Number(value) < 8640000) {
            const roundedValue = Number(value).toFixed(0).toString();
            setDeadline(roundedValue);
            localStorage.setItem("swap_deadline", roundedValue);
        } else {
            setDeadline("5");
        }
    };

    const setPositiveQuantityFrom = (value: string) => {
        if (value.includes("e")) {
            value = Number(value).toString();
        }
        Number(value) >= 0 ? setQuantityFrom(value) : setQuantityFrom("");
        let tokenBalance = Number.MAX_SAFE_INTEGER;
        if (tokenFrom) {
            tokenBalance = Number(getTokenBalance(tokenFrom));
        }
        if (Number(value) > tokenBalance) {
            dispatch(warning({ text: "Insufficient Balance" }));
            if (Number(value) > 999999999999) {
                setQuantityFrom("");
            }
        }
    };

    const onSeekApproval = async (token: string) => {
        if (await checkWrongNetwork()) return;
        setApproveBtnDisabled(true);
        await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
        setApproveBtnDisabled(false);
    };

    const onSwap = async () => {
        if (await checkWrongNetwork()) return;
        if (quantityFrom === "" || parseFloat(quantityFrom) === 0) {
            dispatch(warning({ text: "Please enter a valid amount" }));
        } else {
            // handleOpen();
            const swapSlippage = slippage || "0.01";
            const swapDeadline = deadline || "5";
            setSwapBtnDisabled(true);
            await dispatch(
                swap({
                    address,
                    tokenFrom,
                    tokenTo,
                    value: String(quantityFrom),
                    minReceived: trim(Number(quantityTo) * (1 - Number(swapSlippage) / 100), 4),
                    deadline: swapDeadline,
                    provider,
                    networkID: chainID,
                }),
            );
            setSwapBtnDisabled(false);
            setQuantityFrom("");
        }
    };

    const hasAllowance = useCallback(
        token => {
            if (token === "usdc") return usdcAllowance > 0;
            else if (token === "usdt") return usdtAllowance > 0;
            else if (token === "dai") return daiAllowance > 0;
            return 0;
        },
        [usdcAllowance, usdtAllowance, daiAllowance],
    );

    // useEffect(() => {
    //     if (tokenFrom === "usdc") setFromBalance(trim(Number(usdcBalance), 2));
    //     else if (tokenFrom === "usdt") setFromBalance(trim(Number(usdtBalance), 2));
    //     else if (tokenFrom === "dai") setFromBalance(trim(Number(daiBalance), 2));
    // }, [tokenFrom, usdcBalance, usdtBalance, daiBalance]);

    // useEffect(() => {
    //     if (tokenTo === "usdc") setToBalance(trim(Number(usdcBalance), 2));
    //     else if (tokenTo === "usdt") setToBalance(trim(Number(usdtBalance), 2));
    //     else if (tokenTo === "dai") setToBalance(trim(Number(daiBalance), 2));
    // }, [tokenTo, usdcBalance, usdtBalance, daiBalance]);

    useEffect(() => {
        const timeOutId = setTimeout(() => quantityFromChange(quantityFrom), 250);
        return () => clearTimeout(timeOutId);
    }, [tokenFrom, tokenTo, quantityFrom]);

    // useEffect(() => {
    //     if (isPendingTxn(pendingTransactions, "swap")) {
    //         handleClose();
    //     }
    // }, [pendingTransactions]);

    const switchToken = () => {
        const oldTokenFrom = tokenFrom;
        setTokenFrom(tokenTo);
        setTokenTo(oldTokenFrom);
    };

    async function quantityFromChange(value: string) {
        let toAmount = 0;
        const tokenFromAddress = getLpTokenAddress(chainID, tokenFrom);
        const tokenToAddress = getLpTokenAddress(chainID, tokenTo);
        if (Number(value) > 0 && tokenFromAddress != "" && tokenToAddress != "") {
            const fromFormatUnit = getTokenFormatUnit(tokenFrom);
            const contractValue = ethers.utils.parseUnits(value, fromFormatUnit);
            try {
                const quoteData = await poolContract.getSwapAmount(tokenFromAddress, tokenToAddress, contractValue, false, 0, 0);
                const toFormatUnit = getTokenFormatUnit(tokenTo);
                toAmount = Number(ethers.utils.formatUnits(quoteData.toAmount, toFormatUnit));
                const feeAmount = Number(ethers.utils.formatUnits(quoteData.feeAmount, toFormatUnit));
                const lpAmount = Number(ethers.utils.formatUnits(quoteData.lpAmount, toFormatUnit));
                setHaircut(trim(feeAmount + lpAmount, 6));
                setQuantityTo(trim(toAmount, 6));
                setRate(trim(Number(toAmount) / Number(value), 4));
                setPriceImpact(trim(((Number(toAmount) + Number(feeAmount) + Number(lpAmount) - Number(value)) * -100) / Number(value), 4));
                setSwapBtnDisabled(false);
            } catch (e: any) {
                if (e.data.message.includes("LOW ASSET")) {
                    dispatch(warning({ text: "Swap Amount too high" }));
                }
            }
        } else {
            setQuantityTo("");
        }
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSettingOpen = () => setSettingOpen(true);
    const handleSettingClose = () => setSettingOpen(false);

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: "rgba(11, 0, 30, 0.4)",
        },
        tooltip: {
            backgroundColor: "rgba(11, 0, 30, 0.4)",
            fontSize: 11,
            fontStyle: "Proxima",
        },
    }))(Tooltip);

    return (
        <div className="swap-view">
            <Zoom in={true}>
                <div className="swap-card mb-3">
                    <Grid className="swap-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="swap-card-header">
                                <p className="swap-card-header-title">Swap</p>
                                {address && <img className="setting-icon" alt="" src={SettingIcon} width={28} height={28} onClick={handleSettingOpen} />}
                            </div>
                        </Grid>

                        <div className="swap-card-area">
                            {!address && (
                                <div className="swap-card-wallet-notification">
                                    <div className="swap-card-wallet-connect-btn" onClick={connect}>
                                        <p>Connect Wallet</p>
                                    </div>
                                    <p className="swap-card-wallet-desc-text">Connect your wallet!</p>
                                </div>
                            )}
                            {address && (
                                <div>
                                    <div className="swap-card-action-area bg-primary">
                                        {/* <div className="swap-card-action-row">
                                            <div className="swap-card-tab-panel">
                                                <div className="swap-card-tab-panel-text-left">From</div>
                                            </div>
                                            {tokenFrom && <div className="swap-card-tab-panel-text-right">Balance: {fromBalance}</div>}
                                        </div> */}
                                        <div className="swap-card-action-row">
                                            <Grid container spacing={1}>
                                                <OutlinedInput
                                                    type="number"
                                                    placeholder="0.0"
                                                    className="swap-card-action-input"
                                                    value={quantityFrom}
                                                    onChange={e => setPositiveQuantityFrom(e.target.value)}
                                                    labelWidth={0}
                                                    inputProps={{ style: { textAlign: "left" } }}
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <div onClick={setMax} className="swap-card-action-input-btn">
                                                                <p>MAX</p>
                                                            </div>
                                                        </InputAdornment>
                                                    }
                                                />
                                                {/* <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                                                    Age
                                                </InputLabel> */}
                                                <Select
                                                    disableUnderline={true}
                                                    displayEmpty
                                                    value={tokenFrom}
                                                    label="From"
                                                    className="swap-card-select-input"
                                                    onChange={e => {
                                                        const newTokenFrom = e.target.value as string;
                                                        if (newTokenFrom === tokenTo) {
                                                            setTokenTo(tokenFrom);
                                                        }
                                                        setTokenFrom(newTokenFrom);
                                                        setApproveBtnDisabled(false);
                                                    }}
                                                >
                                                    <MenuItem value="">Select..</MenuItem>
                                                    <MenuItem value="usdc">
                                                        <img className="token-img" alt="" src={USDCIcon} width={40} height={40} />
                                                        <div className="token-info">
                                                            <span className="token-title">USDC</span>
                                                            <p className="token-balance">Balance: {formatValue(usdcBalance)}</p>
                                                        </div>
                                                    </MenuItem>
                                                    <MenuItem value="usdt">
                                                        <img className="token-img" alt="" src={USDTIcon} width={40} height={40} />
                                                        <div className="token-info">
                                                            <span className="token-title">USDT</span>
                                                            <p className="token-balance">Balance: {formatValue(usdtBalance)}</p>
                                                        </div>
                                                    </MenuItem>
                                                    <MenuItem value="dai">
                                                        <img className="token-img" alt="" src={DAIIcon} width={40} height={40} />
                                                        <div className="token-info">
                                                            <span className="token-title">DAI</span>
                                                            <p className="token-balance">Balance: {formatValue(daiBalance)}</p>
                                                        </div>
                                                    </MenuItem>
                                                </Select>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="swap-card-action-area no-gutters">
                                        <div className="swap-card-action-row">
                                            <div className="swap-card-tab-panel-center">
                                                <img className="swap-img" alt="" src={SwapIcon} width={48} height={48} onClick={switchToken} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swap-card-action-area bg-secondary mt-0">
                                        {/* <div className="swap-card-action-row">
                                            <div className="swap-card-tab-panel">
                                                <div className="swap-card-tab-panel-text-left">To</div>
                                            </div>
                                            {tokenTo && <div className="swap-card-tab-panel-text-right">Balance: {toBalance}</div>}
                                        </div> */}
                                        <div className="swap-card-action-row mt-0">
                                            <Grid container spacing={1}>
                                                <OutlinedInput
                                                    type="number"
                                                    placeholder="0.0"
                                                    className="swap-card-action-input"
                                                    value={quantityTo}
                                                    labelWidth={0}
                                                    inputProps={{ style: { textAlign: "left" } }}
                                                    disabled={true}
                                                />
                                                <Select
                                                    disableUnderline={true}
                                                    value={tokenTo}
                                                    label="To"
                                                    className="swap-card-select-input"
                                                    onChange={e => setTokenTo(e.target.value as string)}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="">Select..</MenuItem>
                                                    {tokenFrom !== "usdc" && (
                                                        <MenuItem value="usdc">
                                                            <img className="token-img" alt="" src={USDCIcon} width={40} height={40} />
                                                            <div className="token-info">
                                                                <span className="token-title">USDC</span>
                                                                <p className="token-balance">Balance: {formatValue(usdcBalance)}</p>
                                                            </div>
                                                        </MenuItem>
                                                    )}
                                                    {tokenFrom !== "usdt" && (
                                                        <MenuItem value="usdt">
                                                            <img className="token-img" alt="" src={USDTIcon} width={40} height={40} />
                                                            <div className="token-info">
                                                                <span className="token-title">USDT</span>
                                                                <p className="token-balance">Balance: {formatValue(usdtBalance)}</p>
                                                            </div>
                                                        </MenuItem>
                                                    )}
                                                    {tokenFrom !== "dai" && (
                                                        <MenuItem value="dai">
                                                            <img className="token-img" alt="" src={DAIIcon} width={40} height={40} />
                                                            <div className="token-info">
                                                                <span className="token-title">DAI</span>
                                                                <p className="token-balance">Balance: {formatValue(daiBalance)}</p>
                                                            </div>
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </Grid>
                                        </div>
                                    </div>
                                    {quantityTo && (
                                        <div className="swap-user-data">
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <div className="data-row">
                                                        <div className="data-row-title">
                                                            <p className="data-row-name">RATE</p>
                                                            <LightTooltip title="Exchange rate at which you will receive tokens (incl. fees)" placement="top-start" arrow>
                                                                <img className="tooltip-icon" alt="" src={InfoIcon} width={12} height={12} />
                                                            </LightTooltip>
                                                        </div>
                                                        <p className="data-row-value">
                                                            {isAppLoading || !quantityFrom ? (
                                                                <Skeleton width="80px" />
                                                            ) : (
                                                                <>
                                                                    1 {tokenFrom.toUpperCase()} = {rate} {tokenTo.toUpperCase()}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <div className="data-row">
                                                        <div className="data-row-title">
                                                            <p className="data-row-name">MINIMUM RECEIVED</p>
                                                            <LightTooltip
                                                                title="Minimum Amount you will receive. If amount falls below this, the transaction will instead revert"
                                                                placement="top-start"
                                                                arrow
                                                            >
                                                                <img className="tooltip-icon" alt="" src={InfoIcon} width={12} height={12} />
                                                            </LightTooltip>
                                                        </div>
                                                        <p className="data-row-value">
                                                            {isAppLoading || !quantityFrom ? (
                                                                <Skeleton width="80px" />
                                                            ) : (
                                                                <>
                                                                    {trim(Number(quantityTo) * (1 - Number(slippage) / 100), 4)} {tokenTo.toUpperCase()}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={6}>
                                                    <div className="data-row">
                                                        <div className="data-row-title">
                                                            <p className="data-row-name">PRICE IMPACT</p>
                                                            <LightTooltip title="The impact your trade has on price of this pool" placement="top-start" arrow>
                                                                <img className="tooltip-icon" alt="" src={InfoIcon} width={12} height={12} />
                                                            </LightTooltip>
                                                        </div>
                                                        <p className="data-row-value">{isAppLoading || !quantityFrom ? <Skeleton width="80px" /> : <>{priceImpact} %</>}</p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <div className="data-row">
                                                        <div className="data-row-title">
                                                            <p className="data-row-name">FEES</p>
                                                            <LightTooltip title="Amount that will be paid as fees" placement="top-start" arrow>
                                                                <img className="tooltip-icon" alt="" src={InfoIcon} width={12} height={12} />
                                                            </LightTooltip>
                                                        </div>
                                                        <p className="data-row-value">
                                                            {isAppLoading || !quantityFrom ? (
                                                                <Skeleton width="80px" />
                                                            ) : (
                                                                <>
                                                                    {haircut} {tokenTo.toUpperCase()}
                                                                </>
                                                            )}
                                                        </p>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {/* <div className="data-row">
                                                <p className="data-row-name">One Tap</p>
                                                <p className="data-row-value">
                                                    {isAppLoading || !quantityFrom ? <Skeleton width="80px" /> : <>{trim(Number(oneTapDeposit), 2)}</>}
                                                </p>
                                            </div> */}
                                        </div>
                                    )}
                                    <div className="swap-card-action-area mt-3">
                                        <div className="swap-card-action-row">
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <div className="swap-card-tab-panel">
                                                        {hasAllowance(tokenFrom) ? (
                                                            <div
                                                                className="swap-card-tab-panel-btn"
                                                                onClick={() => {
                                                                    if (isPendingTxn(pendingTransactions, "swap") || swapBtnDisabled) return;
                                                                    onSwap();
                                                                }}
                                                            >
                                                                <p>{txnButtonText(pendingTransactions, "swap", "Swap Tokens")}</p>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="swap-card-tab-panel-btn"
                                                                onClick={() => {
                                                                    if (isPendingTxn(pendingTransactions, "approve_" + tokenFrom) || approveBtnDisabled) return;
                                                                    onSeekApproval(tokenFrom);
                                                                }}
                                                            >
                                                                <p>{txnButtonText(pendingTransactions, "approve_" + tokenFrom, "Approve")}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grid>
                </div>
            </Zoom>
            {/* <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="modal-box">
                    <h1 className="modal-larger-text">Waiting for confirmation</h1>
                    <div className="modal-body">
                        <p className="modal-normal-text">From</p>
                        <div className="modal-text-area">
                            <img src={getTokenSVG(tokenFrom)} width={40} height={40} />
                            <span className="text-title">{tokenFrom}</span>
                            <span className="text-number">{quantityFrom}</span>
                        </div>
                        <p className="modal-normal-text">To</p>
                        <div className="modal-text-area">
                            <img src={getTokenSVG(tokenTo)} width={40} height={40} />
                            <span className="text-title">{tokenTo}</span>
                            <span className="text-number">{quantityTo}</span>
                        </div>
                    </div>
                </Box>
            </Modal> */}

            <Modal open={settingOpen} onClose={handleSettingClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="swap-modal-box">
                    <div className="modal-header">
                        <h1 className="modal-title">Swap Settings</h1>
                        <img className="close-icon modal-close" alt="" src={CloseIcon} width={28} height={28} onClick={handleSettingClose} />
                        {/* <span className="modal-close" onClick={handleSettingClose}>
                            X
                        </span> */}
                    </div>
                    <div className="modal-body">
                        <p className="modal-normal-text text-secondary">Slippage Tolerance</p>
                        <RadioGroup row aria-label="slippageTolerance" name="slippage" value={slippage} onChange={e => setPositiveSlippage(e.target.value)} className="radio-input">
                            <FormControlLabel value="0.1" control={<Radio />} label="0.1%" labelPlacement="end" />
                            <FormControlLabel value="0.5" control={<Radio />} label="0.5%" labelPlacement="end" />
                            <FormControlLabel value="1" control={<Radio />} label="1%" labelPlacement="end" />
                        </RadioGroup>
                        <OutlinedInput
                            type="number"
                            placeholder="Slippage %"
                            className="modal-input"
                            value={slippage}
                            onChange={e => setPositiveSlippage(e.target.value)}
                            labelWidth={0}
                        />
                        <p className="modal-normal-text">Transaction Deadline</p>
                        <RadioGroup
                            row
                            aria-label="transactionDeadline"
                            name="deadline"
                            value={deadline}
                            onChange={e => setLimitedDeadline(e.target.value)}
                            className="radio-input"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="1 minute" labelPlacement="end" />
                            <FormControlLabel value="5" control={<Radio />} label="5 minutes" labelPlacement="end" />
                            <FormControlLabel value="30" control={<Radio />} label="30 minutes" labelPlacement="end" />
                        </RadioGroup>
                        <OutlinedInput
                            type="number"
                            placeholder="Deadline"
                            className="modal-input"
                            value={deadline}
                            onChange={e => setLimitedDeadline(e.target.value)}
                            labelWidth={0}
                        />
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
export default Swap;
