const cache: { [key: string]: number } = {};

export const loadTokenPrices = async () => {
    // cache["USDC"] = data["usd-coin"].usd;
    // cache["OHM"] = data["olympus"].usd;
    cache["MNT"] = 0.1;
};

export const getTokenPrice = (symbol: string): number => {
    return cache[symbol];
};
