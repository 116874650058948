import { BigNumber, ethers } from "ethers";
import { getAddresses } from "../../constants";
import { StableReserveContract, PoolContract, MasterMantisContract } from "../../abi";
import { clearPendingTxn, fetchPendingTxns } from "./pending-txns-slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances } from "./account-slice";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { Networks } from "../../constants/blockchain";
import { warning, success, info, error } from "./messages-slice";
import { messages } from "../../constants/messages";
import { getGasPrice } from "../../helpers/get-gas-price";
import { getTokenAddress, getTokenFormatUnit, getTokenPid } from "src/helpers/get-token-data";
import { tokenToString } from "typescript";
import { trim } from "src/helpers";

interface IChangeApproval {
    token: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const changeApproval = createAsyncThunk("stake/changeApproval", async ({ token, provider, address, networkID }: IChangeApproval, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);

    const signer = provider.getSigner();
    const usdcContract = new ethers.Contract(addresses.USDC_ADDRESS, StableReserveContract, signer);
    const usdtContract = new ethers.Contract(addresses.USDT_ADDRESS, StableReserveContract, signer);
    const daiContract = new ethers.Contract(addresses.DAI_ADDRESS, StableReserveContract, signer);
    const lp_usdcContract = new ethers.Contract(addresses.ASSET_USDC_ADDRESS, StableReserveContract, signer);
    const lp_usdtContract = new ethers.Contract(addresses.ASSET_USDT_ADDRESS, StableReserveContract, signer);
    const lp_daiContract = new ethers.Contract(addresses.ASSET_DAI_ADDRESS, StableReserveContract, signer);

    let approveTx;
    try {
        const gasPrice = await getGasPrice(provider);

        if (token === "usdc") {
            approveTx = await usdcContract.approve(addresses.POOL_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        } else if (token === "usdt") {
            approveTx = await usdtContract.approve(addresses.POOL_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        } else if (token === "dai") {
            approveTx = await daiContract.approve(addresses.POOL_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        } else if (token === "lp_usdc") {
            approveTx = await lp_usdcContract.approve(addresses.POOL_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        } else if (token === "lp_usdt") {
            approveTx = await lp_usdtContract.approve(addresses.POOL_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        } else if (token === "lp_dai") {
            approveTx = await lp_daiContract.approve(addresses.POOL_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        } else if (token === "staked_lp_usdc") {
            approveTx = await lp_usdcContract.approve(addresses.MASTER_MANTIS_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        } else if (token === "staked_lp_usdt") {
            approveTx = await lp_usdtContract.approve(addresses.MASTER_MANTIS_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        } else if (token === "staked_lp_dai") {
            approveTx = await lp_daiContract.approve(addresses.MASTER_MANTIS_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        }

        const text = "Approve " + token.toUpperCase();
        const pendingTxnType = "approve_" + token;

        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await approveTx.wait();
    } catch (err: any) {
        dispatch(error({ text: messages.something_wrong, error: err.message }));
        return;
    } finally {
        if (approveTx) {
            dispatch(clearPendingTxn(approveTx.hash));
        }
    }

    let allowanceData = {};
    if (token === "usdc") {
        const allowance_usdc = await usdcContract.allowance(address, addresses.POOL_ADDRESS);
        allowanceData = { allowance_pool: { usdc: Number(allowance_usdc) } };
    } else if (token === "usdt") {
        const allowance_usdt = await usdtContract.allowance(address, addresses.POOL_ADDRESS);
        allowanceData = { allowance_pool: { usdt: Number(allowance_usdt) } };
    } else if (token === "dai") {
        const allowance_dai = await daiContract.allowance(address, addresses.POOL_ADDRESS);
        allowanceData = { allowance_pool: { dai: Number(allowance_dai) } };
    } else if (token === "lp_usdc") {
        const allowance_lp_usdc = await lp_usdcContract.allowance(address, addresses.POOL_ADDRESS);
        allowanceData = { allowance_pool: { lp_usdc: Number(allowance_lp_usdc) } };
    } else if (token === "lp_usdt") {
        const allowance_lp_usdt = await lp_usdtContract.allowance(address, addresses.POOL_ADDRESS);
        allowanceData = { allowance_pool: { lp_usdt: Number(allowance_lp_usdt) } };
    } else if (token === "lp_dai") {
        const allowance_lp_dai = await lp_daiContract.allowance(address, addresses.POOL_ADDRESS);
        allowanceData = { allowance_pool: { lp_dai: Number(allowance_lp_dai) } };
    } else if (token === "staked_lp_usdc") {
        const allowance_usdc = await lp_usdcContract.allowance(address, addresses.MASTER_MANTIS_ADDRESS);
        allowanceData = { allowance_master: { lp_usdc: Number(allowance_usdc) } };
    } else if (token === "staked_lp_usdt") {
        const allowance_usdt = await lp_usdtContract.allowance(address, addresses.MASTER_MANTIS_ADDRESS);
        allowanceData = { allowance_master: { lp_usdt: Number(allowance_usdt) } };
    } else if (token === "staked_lp_dai") {
        const allowance_dai = await lp_daiContract.allowance(address, addresses.MASTER_MANTIS_ADDRESS);
        allowanceData = { allowance_master: { lp_dai: Number(allowance_dai) } };
    }

    return dispatch(fetchAccountSuccess(allowanceData));
});

interface IDeposit {
    action: string;
    value: string;
    autoStake: boolean;
    deadline: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const deposit = createAsyncThunk("stake/deposit", async ({ action, value, autoStake, deadline, provider, address, networkID }: IDeposit, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const poolContract = new ethers.Contract(addresses.POOL_ADDRESS, PoolContract, signer);

    let stakeTx;

    try {
        const gasPrice = await getGasPrice(provider);
        const transactionDeadline = Math.floor(Date.now() / 1000) + Number(deadline) * 60;
        if (action === "usdc") {
            stakeTx = await poolContract.deposit(addresses.USDC_ADDRESS, address, ethers.utils.parseUnits(value, "mwei"), autoStake, transactionDeadline, { gasPrice });
        } else if (action === "usdt") {
            stakeTx = await poolContract.deposit(addresses.USDT_ADDRESS, address, ethers.utils.parseUnits(value, "mwei"), autoStake, transactionDeadline, { gasPrice });
        } else if (action === "dai") {
            stakeTx = await poolContract.deposit(addresses.DAI_ADDRESS, address, ethers.utils.parseUnits(value, "ether"), autoStake, transactionDeadline, { gasPrice });
        } else {
            dispatch(warning({ text: "Wrong token. Fix it" }));
            return;
        }
        const pendingTxnType = "deposit_" + action;
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: action, type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await stakeTx.wait();
    } catch (err: any) {
        if (err.code === -32603 && err.message.indexOf("ds-math-sub-underflow") >= 0) {
            dispatch(error({ text: "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow", error: err }));
        } else {
            dispatch(error({ text: messages.something_wrong, error: err }));
        }
        return;
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(getBalances({ address, tokens: [action], networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
});

interface IWithdraw {
    action: string;
    value: string;
    slippage: string;
    deadline: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const withdraw = createAsyncThunk("stake/withdraw", async ({ action, value, slippage, deadline, provider, address, networkID }: IWithdraw, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const poolContract = new ethers.Contract(addresses.POOL_ADDRESS, PoolContract, signer);

    let stakeTx;

    try {
        const gasPrice = await getGasPrice(provider);
        const transactionDeadline = Math.floor(Date.now() / 1000) + Number(deadline) * 60;
        const formatUnit = getTokenFormatUnit(action);
        const liquidity = ethers.utils.parseUnits(value, formatUnit);
        const minValue = trim((Number(value) * (100 - Number(slippage))) / 100, 6);
        const minLiquidity = ethers.utils.parseUnits(minValue, formatUnit);
        if (action === "usdc") {
            stakeTx = await poolContract.withdraw(addresses.USDC_ADDRESS, address, liquidity, minLiquidity, transactionDeadline, { gasPrice });
        } else if (action == "usdt") {
            stakeTx = await poolContract.withdraw(addresses.USDT_ADDRESS, address, liquidity, minLiquidity, transactionDeadline, { gasPrice });
        } else if (action == "dai") {
            stakeTx = await poolContract.withdraw(addresses.DAI_ADDRESS, address, liquidity, minLiquidity, transactionDeadline, { gasPrice });
        } else {
            dispatch(warning({ text: "Wrong token. Fix it" }));
            return;
        }
        const pendingTxnType = "withdraw_" + action;
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: action, type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await stakeTx.wait();
    } catch (err: any) {
        if (err.code === -32603 && err.message.indexOf("ds-math-sub-underflow") >= 0) {
            dispatch(error({ text: "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow", error: err }));
        } else {
            dispatch(error({ text: messages.something_wrong, error: err }));
        }
        return;
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(getBalances({ address, tokens: [action], networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
});

interface IChangeStake {
    action: string;
    value: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const stake = createAsyncThunk("stake/stake", async ({ action, value, provider, address, networkID }: IChangeStake, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const masterPlatypusContract = new ethers.Contract(addresses.MASTER_MANTIS_ADDRESS, MasterMantisContract, signer);

    let stakeTx;

    try {
        const gasPrice = await getGasPrice(provider);
        const formatUnit = getTokenFormatUnit(action);
        const amount = ethers.utils.parseUnits(value, formatUnit);
        if (action === "usdc") {
            stakeTx = await masterPlatypusContract.deposit(address, 0, amount, { gasPrice });
        } else if (action == "usdt") {
            stakeTx = await masterPlatypusContract.deposit(address, 1, amount, { gasPrice });
        } else if (action == "dai") {
            stakeTx = await masterPlatypusContract.deposit(address, 2, amount, { gasPrice });
        } else {
            dispatch(warning({ text: "Wrong token. Fix it" }));
            return;
        }
        const pendingTxnType = "stake_" + action;
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: action, type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await stakeTx.wait();
    } catch (err: any) {
        if (err.code === -32603 && err.message.indexOf("ds-math-sub-underflow") >= 0) {
            dispatch(error({ text: "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow", error: err }));
        } else {
            dispatch(error({ text: messages.something_wrong, error: err }));
        }
        return;
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(getBalances({ address, tokens: [action], networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
});

export const unstake = createAsyncThunk("stake/unstake", async ({ action, value, provider, address, networkID }: IChangeStake, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const masterPlatypusContract = new ethers.Contract(addresses.MASTER_MANTIS_ADDRESS, MasterMantisContract, signer);

    let stakeTx;

    try {
        const gasPrice = await getGasPrice(provider);
        const formatUnit = getTokenFormatUnit(action);
        const amount = ethers.utils.parseUnits(value, formatUnit);
        if (action === "usdc") {
            stakeTx = await masterPlatypusContract.withdraw(0, amount, { gasPrice });
        } else if (action == "usdt") {
            stakeTx = await masterPlatypusContract.withdraw(1, amount, { gasPrice });
        } else if (action == "dai") {
            stakeTx = await masterPlatypusContract.withdraw(2, amount, { gasPrice });
        } else {
            dispatch(warning({ text: "Wrong token. Fix it" }));
            return;
        }
        const pendingTxnType = "unstake_" + action;
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: action, type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await stakeTx.wait();
    } catch (err: any) {
        if (err.code === -32603 && err.message.indexOf("ds-math-sub-underflow") >= 0) {
            dispatch(error({ text: "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow", error: err }));
        } else {
            dispatch(error({ text: messages.something_wrong, error: err }));
        }
        return;
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(getBalances({ address, tokens: [action], networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
});

interface IClaim {
    action: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const claim = createAsyncThunk("stake/claim", async ({ action, provider, address, networkID }: IClaim, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const masterPlatypusContract = new ethers.Contract(addresses.MASTER_MANTIS_ADDRESS, MasterMantisContract, signer);

    let stakeTx;

    try {
        const gasPrice = await getGasPrice(provider);
        if (action === "usdc") {
            stakeTx = await masterPlatypusContract.claim([0], { gasPrice });
        } else if (action == "usdt") {
            stakeTx = await masterPlatypusContract.claim([1], { gasPrice });
        } else if (action == "dai") {
            stakeTx = await masterPlatypusContract.claim([2], { gasPrice });
        } else if (action == "all") {
            stakeTx = await masterPlatypusContract.claim([0, 1, 2], { gasPrice });
        } else {
            dispatch(warning({ text: "Wrong token. Fix it" }));
            return;
        }
        const pendingTxnType = "claim_" + action;
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: action, type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await stakeTx.wait();
    } catch (err: any) {
        if (err.code === -32603 && err.message.indexOf("ds-math-sub-underflow") >= 0) {
            dispatch(error({ text: "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow", error: err }));
        } else {
            dispatch(error({ text: messages.something_wrong, error: err }));
        }
        return;
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(getBalances({ address, tokens: ["mnt", "vemnt"], networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
});

interface ISwap {
    tokenFrom: string;
    tokenTo: string;
    value: string;
    minReceived: string;
    deadline: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const swap = createAsyncThunk("stake/swap", async ({ tokenFrom, tokenTo, value, minReceived, deadline, provider, address, networkID }: ISwap, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const poolContract = new ethers.Contract(addresses.POOL_ADDRESS, PoolContract, signer);

    let stakeTx;

    try {
        const gasPrice = await getGasPrice(provider);
        const formatUnit = getTokenFormatUnit(tokenFrom);
        const toFormatUnit = getTokenFormatUnit(tokenTo);
        const amount = ethers.utils.parseUnits(value, formatUnit);
        const minAmount = ethers.utils.parseUnits(minReceived.toString(), toFormatUnit);
        const transactionDeadline = Math.floor(Date.now() / 1000) + Number(deadline) * 60;
        const tokenFromAddress = getTokenAddress(networkID, tokenFrom);
        const tokenToAddress = getTokenAddress(networkID, tokenTo);

        stakeTx = await poolContract.swap(tokenFromAddress, tokenToAddress, address, amount, minAmount, transactionDeadline, { gasPrice });
        const pendingTxnType = "swap";
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: "swap", type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await stakeTx.wait();
    } catch (err: any) {
        if (err.code === -32603 && err.message.indexOf("ds-math-sub-underflow") >= 0) {
            dispatch(error({ text: "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow", error: err }));
        } else {
            dispatch(error({ text: messages.something_wrong, error: err }));
        }
        return;
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(getBalances({ address, tokens: [tokenFrom + "_only", tokenTo + "_only"], networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
});

interface IOneTap {
    tokenFrom: string;
    tokenTo: string;
    value: string;
    staked: boolean;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const oneTap = createAsyncThunk("stake/onetap", async ({ tokenFrom, tokenTo, value, staked, provider, address, networkID }: IOneTap, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const poolContract = new ethers.Contract(addresses.POOL_ADDRESS, PoolContract, signer);

    let stakeTx;

    try {
        const gasPrice = await getGasPrice(provider);
        const formatUnit = getTokenFormatUnit(tokenFrom);
        const amount = ethers.utils.parseUnits(value, formatUnit);
        const tokenFromAddress = getTokenAddress(networkID, tokenFrom);
        const tokenToAddress = getTokenAddress(networkID, tokenTo);
        stakeTx = await poolContract.oneTap(tokenFromAddress, tokenToAddress, address, amount, 0, staked, staked, { gasPrice });
        const pendingTxnType = "onetap";
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: "onetap", type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await stakeTx.wait();
    } catch (err: any) {
        if (err.code === -32603 && err.message.indexOf("ds-math-sub-underflow") >= 0) {
            dispatch(error({ text: "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow", error: err }));
        } else {
            dispatch(error({ text: messages.something_wrong, error: err }));
        }
        return;
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(getBalances({ address, tokens: [tokenFrom, tokenTo], networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
});

interface IVote {
    tokens: string[];
    values: string[];
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const vote = createAsyncThunk("stake/vote", async ({ tokens, values, provider, address, networkID }: IVote, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const masterPlatypusContract = new ethers.Contract(addresses.MASTER_MANTIS_ADDRESS, MasterMantisContract, signer);

    let stakeTx;

    try {
        const gasPrice = await getGasPrice(provider);
        let votes = [];
        let totalVotes = BigNumber.from("0");
        for (let i = 0; i < tokenToString.length; i++) {
            let token = tokens[i];
            let value = values[i];
            let pid = getTokenPid(token);
            let formatUnit = getTokenFormatUnit(token);
            let amount = ethers.utils.parseUnits(value, formatUnit);
            votes.push({ pid: pid, amount: amount });
            totalVotes = totalVotes.add(amount);
        }
        const payload = {
            totalVotes: totalVotes,
            votes: votes,
        };
        stakeTx = await masterPlatypusContract.vote(payload, { gasPrice });
        const pendingTxnType = "vote";
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: "vote", type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await stakeTx.wait();
    } catch (err: any) {
        if (err.code === -32603 && err.message.indexOf("ds-math-sub-underflow") >= 0) {
            dispatch(error({ text: "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow", error: err }));
        } else {
            dispatch(error({ text: messages.something_wrong, error: err }));
        }
        return;
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(info({ text: messages.tx_successfully_complete }));
    return;
});
