import { Grid, Zoom } from "@material-ui/core";
import { useEffect, useState } from "react";
import { apiGetRequest } from "src/helpers/api-request";
import { useWeb3Context } from "src/hooks";
import OneIcon from "../../assets/icons/one.svg";
import TwoIcon from "../../assets/icons/two.svg";
import ThreeIcon from "../../assets/icons/three.svg";
import "./leaderboard.scss";
import { shortenWallet } from "src/helpers";

function Leaderboard() {
    const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();

    const getUrl = "https://api.mantissa.finance/api/testnet/leaderboard/";

    const [volumeFirst, setVolumeFirst] = useState<string>("");
    const [volumeSecond, setVolumeSecond] = useState<string>("");
    const [volumeThird, setVolumeThird] = useState<string>("");
    const [mntFirst, setMntFirst] = useState<string>("");
    const [mntSecond, setMntSecond] = useState<string>("");
    const [mntThird, setMntThird] = useState<string>("");
    const [profitFirst, setProfitFirst] = useState<string>("");
    const [profitSecond, setProfitSecond] = useState<string>("");
    const [profitThird, setProfitThird] = useState<string>("");

    const [volumeFirstAmount, setVolumeFirstAmount] = useState<string>("");
    const [volumeSecondAmount, setVolumeSecondAmount] = useState<string>("");
    const [volumeThirdAmount, setVolumeThirdAmount] = useState<string>("");
    const [mntFirstAmount, setMntFirstAmount] = useState<string>("");
    const [mntSecondAmount, setMntSecondAmount] = useState<string>("");
    const [mntThirdAmount, setMntThirdAmount] = useState<string>("");
    const [profitFirstAmount, setProfitFirstAmount] = useState<string>("");
    const [profitSecondAmount, setProfitSecondAmount] = useState<string>("");
    const [profitThirdAmount, setProfitThirdAmount] = useState<string>("");

    const getLeaderData = async () => {
        if (await checkWrongNetwork()) return;
        try {
            const response = await apiGetRequest(getUrl);
            for (let i = 0; i < response.length; i++) {
                if (response[i].contest_type == "mnt") {
                    if (response[i].position == 1) {
                        setMntFirst(response[i].account);
                        setMntFirstAmount(response[i].amount);
                    } else if (response[i].position == 2) {
                        setMntSecond(response[i].account);
                        setMntSecondAmount(response[i].amount);
                    } else if (response[i].position == 3) {
                        setMntThird(response[i].account);
                        setMntThirdAmount(response[i].amount);
                    }
                } else if (response[i].contest_type == "profit") {
                    if (response[i].position == 1) {
                        setProfitFirst(response[i].account);
                        setProfitFirstAmount(response[i].amount);
                    } else if (response[i].position == 2) {
                        setProfitSecond(response[i].account);
                        setProfitSecondAmount(response[i].amount);
                    } else if (response[i].position == 3) {
                        setProfitThird(response[i].account);
                        setProfitThirdAmount(response[i].amount);
                    }
                } else if (response[i].contest_type == "volume") {
                    if (response[i].position == 1) {
                        setVolumeFirst(response[i].account);
                        setVolumeFirstAmount(response[i].amount);
                    } else if (response[i].position == 2) {
                        setVolumeSecond(response[i].account);
                        setVolumeSecondAmount(response[i].amount);
                    } else if (response[i].position == 3) {
                        setVolumeThird(response[i].account);
                        setVolumeThirdAmount(response[i].amount);
                    }
                }
            }
        } catch {}
    };

    useEffect(() => {
        if (address) {
            getLeaderData();
        }
    }, []);

    return (
        <div className="leaderboard-view">
            <Zoom in={true}>
                <div className="leaderboard-card mb-3">
                    <Grid className="leaderboard-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="leaderboard-card-header">
                                <p className="leaderboard-card-header-title">Leaderboard</p>
                            </div>
                            <p className="leaderboard-card-subheader">Note - Leaderboard will be updated weekly</p>
                        </Grid>

                        <div className="leaderboard-card-area">
                            <div className="leaderboard-token-data">
                                <h4>Most Volume Traded</h4>
                                <div className="data-row">
                                    <p className="data-row-name">
                                        <img className="btn-icon" alt="" src={OneIcon} width={16} height={16} />
                                    </p>
                                    <p className="data-row-value">{volumeFirstAmount}</p>
                                    <p className="data-row-link">{shortenWallet(volumeFirst)}</p>
                                </div>
                                <div className="data-row">
                                    <p className="data-row-name">
                                        <img className="btn-icon" alt="" src={TwoIcon} width={16} height={16} />
                                    </p>
                                    <p className="data-row-value">{volumeSecondAmount}</p>
                                    <p className="data-row-link">{shortenWallet(volumeSecond)}</p>
                                </div>
                                <div className="data-row">
                                    <p className="data-row-name">
                                        <img className="btn-icon" alt="" src={ThreeIcon} width={16} height={16} />
                                    </p>
                                    <p className="data-row-value">{volumeThirdAmount}</p>
                                    <p className="data-row-link">{shortenWallet(volumeThird)}</p>
                                </div>
                                <h4>Most MNT Claimed</h4>
                                <div className="data-row">
                                    <p className="data-row-name">
                                        <img className="btn-icon" alt="" src={OneIcon} width={16} height={16} />
                                    </p>
                                    <p className="data-row-value">{mntFirstAmount}</p>
                                    <p className="data-row-link">{shortenWallet(mntFirst)}</p>
                                </div>
                                <div className="data-row">
                                    <p className="data-row-name">
                                        <img className="btn-icon" alt="" src={TwoIcon} width={16} height={16} />
                                    </p>
                                    <p className="data-row-value">{mntSecondAmount}</p>
                                    <p className="data-row-link">{shortenWallet(mntSecond)}</p>
                                </div>
                                <div className="data-row">
                                    <p className="data-row-name">
                                        <img className="btn-icon" alt="" src={ThreeIcon} width={16} height={16} />
                                    </p>
                                    <p className="data-row-value">{mntThirdAmount}</p>
                                    <p className="data-row-link">{shortenWallet(mntThird)}</p>
                                </div>
                                <h4>Most Profitable Swap</h4>
                                <div className="data-row">
                                    <p className="data-row-name">
                                        <img className="btn-icon" alt="" src={OneIcon} width={16} height={16} />
                                    </p>
                                    <p className="data-row-value">{profitFirstAmount}</p>
                                    <p className="data-row-link">{shortenWallet(profitFirst)}</p>
                                </div>
                                <div className="data-row">
                                    <p className="data-row-name">
                                        <img className="btn-icon" alt="" src={TwoIcon} width={16} height={16} />
                                    </p>
                                    <p className="data-row-value">{profitSecondAmount}</p>
                                    <p className="data-row-link">{shortenWallet(profitSecond)}</p>
                                </div>
                                <div className="data-row">
                                    <p className="data-row-name">
                                        <img className="btn-icon" alt="" src={ThreeIcon} width={16} height={16} />
                                    </p>
                                    <p className="data-row-value">{profitThirdAmount}</p>
                                    <p className="data-row-link">{shortenWallet(profitThird)}</p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </div>
            </Zoom>
        </div>
    );
}
export default Leaderboard;
