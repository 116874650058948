import { ethers } from "ethers";
import { getAddresses } from "../../constants";
import { MasterMantisContract, StableReserveContract, VeMNTTokenContract } from "../../abi/";
import { setAll } from "../../helpers";

import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { Networks } from "../../constants/blockchain";
import { RootState } from "../store";

interface IGetBalances {
    address: string;
    tokens: string[];
    networkID: Networks;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}

export const getBalances = createAsyncThunk("account/getBalances", async ({ address, tokens, networkID, provider }: IGetBalances): Promise<any> => {
    const addresses = getAddresses(networkID);
    const vemntContract = new ethers.Contract(addresses.VEMNT_ADDRESS, VeMNTTokenContract, provider);
    const masterMantisContract = new ethers.Contract(addresses.MASTER_MANTIS_ADDRESS, MasterMantisContract, provider);

    let balanceObj: any = {};
    let claimObj: any = {};
    if (tokens.includes("mnt")) {
        const mntContract = new ethers.Contract(addresses.MNT_ADDRESS, StableReserveContract, provider);
        const mntBalance = await mntContract.balanceOf(address);
        balanceObj.mnt = ethers.utils.formatUnits(mntBalance, "ether");

        const userMNTData = await vemntContract.userData(address);
        const stakedMNT = userMNTData.amount;
        balanceObj.staked_mnt = ethers.utils.formatUnits(stakedMNT, "ether");
    }

    if (tokens.includes("vemnt")) {
        const vemntBalance = await vemntContract.balanceOf(address);
        balanceObj.vemnt = ethers.utils.formatUnits(vemntBalance, "ether");
        const vemntClaimable = await vemntContract.claimable(address);
        claimObj.vemnt = ethers.utils.formatUnits(vemntClaimable, "ether");
    }

    if (tokens.includes("usdc_only")) {
        const usdcContract = new ethers.Contract(addresses.USDC_ADDRESS, StableReserveContract, provider);
        const usdcBalance = await usdcContract.balanceOf(address);
        balanceObj.usdc = ethers.utils.formatUnits(usdcBalance, "mwei");
    }

    if (tokens.includes("usdc")) {
        const usdcContract = new ethers.Contract(addresses.USDC_ADDRESS, StableReserveContract, provider);
        const usdcBalance = await usdcContract.balanceOf(address);
        balanceObj.usdc = ethers.utils.formatUnits(usdcBalance, "mwei");

        const lp_usdcContract = new ethers.Contract(addresses.ASSET_USDC_ADDRESS, StableReserveContract, provider);
        const lp_usdcBalance = await lp_usdcContract.balanceOf(address);
        balanceObj.lp_usdc = ethers.utils.formatUnits(lp_usdcBalance, "mwei");

        const staked_lp_usdcData = await masterMantisContract.userInfo(0, address);
        const staked_lp_usdcBalance = staked_lp_usdcData.amount;
        balanceObj.staked_lp_usdc = ethers.utils.formatUnits(staked_lp_usdcBalance, "mwei");
    }

    if (tokens.includes("usdt_only")) {
        const usdtContract = new ethers.Contract(addresses.USDT_ADDRESS, StableReserveContract, provider);
        const usdtBalance = await usdtContract.balanceOf(address);
        balanceObj.usdt = ethers.utils.formatUnits(usdtBalance, "mwei");
    }

    if (tokens.includes("usdt")) {
        const usdtContract = new ethers.Contract(addresses.USDT_ADDRESS, StableReserveContract, provider);
        const usdtBalance = await usdtContract.balanceOf(address);
        balanceObj.usdt = ethers.utils.formatUnits(usdtBalance, "mwei");

        const lp_usdtContract = new ethers.Contract(addresses.ASSET_USDT_ADDRESS, StableReserveContract, provider);
        const lp_usdtBalance = await lp_usdtContract.balanceOf(address);
        balanceObj.lp_usdt = ethers.utils.formatUnits(lp_usdtBalance, "mwei");

        const staked_lp_usdtData = await masterMantisContract.userInfo(1, address);
        const staked_lp_usdtBalance = staked_lp_usdtData.amount;
        balanceObj.staked_lp_usdt = ethers.utils.formatUnits(staked_lp_usdtBalance, "mwei");
    }

    if (tokens.includes("dai_only")) {
        const daiContract = new ethers.Contract(addresses.DAI_ADDRESS, StableReserveContract, provider);
        const daiBalance = await daiContract.balanceOf(address);
        balanceObj.dai = ethers.utils.formatUnits(daiBalance, "ether");
    }

    if (tokens.includes("dai")) {
        const daiContract = new ethers.Contract(addresses.DAI_ADDRESS, StableReserveContract, provider);
        const daiBalance = await daiContract.balanceOf(address);
        balanceObj.dai = ethers.utils.formatUnits(daiBalance, "ether");

        const lp_daiContract = new ethers.Contract(addresses.ASSET_DAI_ADDRESS, StableReserveContract, provider);
        const lp_daiBalance = await lp_daiContract.balanceOf(address);
        balanceObj.lp_dai = ethers.utils.formatUnits(lp_daiBalance, "ether");

        const staked_lp_daiData = await masterMantisContract.userInfo(2, address);
        const staked_lp_daiBalance = staked_lp_daiData.amount;
        balanceObj.staked_lp_dai = ethers.utils.formatUnits(staked_lp_daiBalance, "ether");
    }

    return {
        balances: balanceObj,
        claim: claimObj,
    };
});

interface ILoadAccountDetails {
    address: string;
    networkID: Networks;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}

interface IUserAccountDetails {
    balances: {
        usdc: string;
        usdt: string;
        dai: string;
    };
    allowance_pool: {
        usdc: Number;
        usdt: Number;
        dai: Number;
    };
}

export const loadAccountDetails = createAsyncThunk("account/loadAccountDetails", async ({ networkID, provider, address }: ILoadAccountDetails): Promise<IUserAccountDetails> => {
    let usdcBalance = 0;
    let usdtBalance = 0;
    let daiBalance = 0;

    let usdcAllowance = 0;
    let usdtAllowance = 0;
    let daiAllowance = 0;

    const addresses = getAddresses(networkID);
    if (addresses.USDC_ADDRESS) {
        const usdcContract = new ethers.Contract(addresses.USDC_ADDRESS, StableReserveContract, provider);
        usdcBalance = await usdcContract.balanceOf(address);
        usdcAllowance = await usdcContract.allowance(address, addresses.POOL_ADDRESS);
    }

    if (addresses.USDT_ADDRESS) {
        const usdtContract = new ethers.Contract(addresses.USDT_ADDRESS, StableReserveContract, provider);
        usdtBalance = await usdtContract.balanceOf(address);
        usdtAllowance = await usdtContract.allowance(address, addresses.POOL_ADDRESS);
    }

    if (addresses.DAI_ADDRESS) {
        const daiContract = new ethers.Contract(addresses.DAI_ADDRESS, StableReserveContract, provider);
        daiBalance = await daiContract.balanceOf(address);
        daiAllowance = await daiContract.allowance(address, addresses.POOL_ADDRESS);
    }

    return {
        balances: {
            usdc: ethers.utils.formatUnits(usdcBalance, "mwei"),
            usdt: ethers.utils.formatUnits(usdtBalance, "mwei"),
            dai: ethers.utils.formatUnits(daiBalance, "ether"),
        },
        allowance_pool: {
            usdc: Number(usdcAllowance),
            usdt: Number(usdtAllowance),
            dai: Number(daiAllowance),
        },
    };
});

export interface IAccountSlice {
    balances: {
        mnt: string;
        vemnt: string;
        usdc: string;
        usdt: string;
        dai: string;
        lp_usdc: string;
        lp_usdt: string;
        lp_dai: string;
        staked_lp_usdc: string;
        staked_lp_usdt: string;
        staked_lp_dai: string;
        staked_mnt: string;
    };
    loading: boolean;
    claim: {
        lp_usdc: string;
        lp_usdt: string;
        lp_dai: string;
        vemnt: string;
    };
    allowance_pool: {
        usdc: number;
        usdt: number;
        dai: number;
        lp_usdc: number;
        lp_usdt: number;
        lp_dai: number;
    };
    allowance_master: {
        lp_usdc: number;
        lp_usdt: number;
        lp_dai: number;
    };
    allowance_vemnt: {
        mnt: number;
    };
    allowance_marketplace: {
        usdc: number;
        usdt: number;
        dai: number;
    };
}

const initialState: IAccountSlice = {
    loading: true,
    balances: { mnt: "", vemnt: "", usdc: "", usdt: "", dai: "", lp_usdc: "", lp_usdt: "", lp_dai: "", staked_lp_usdc: "", staked_lp_usdt: "", staked_lp_dai: "", staked_mnt: "" },
    claim: { lp_usdc: "", lp_usdt: "", lp_dai: "", vemnt: "" },
    allowance_pool: { usdc: 0, usdt: 0, dai: 0, lp_usdc: 0, lp_usdt: 0, lp_dai: 0 },
    allowance_master: { lp_usdc: 0, lp_usdt: 0, lp_dai: 0 },
    allowance_vemnt: { mnt: 0 },
    allowance_marketplace: { usdc: 0, usdt: 0, dai: 0 },
};

const accountSlice: any = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAccountDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(getBalances.pending, state => {
                state.loading = true;
            })
            .addCase(getBalances.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getBalances.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            });
    },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
