import * as React from "react";
import "./pool.scss";
import { ethers } from "ethers";
import { useWeb3Context } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "src/store/slices/state.interface";
import { changeApproval, claim, oneTap } from "src/store/slices/pool-thunk";
import { useState, useCallback, useEffect } from "react";
import { Grid, InputAdornment, OutlinedInput, Zoom, Box, Modal, Select, MenuItem, Button } from "@material-ui/core";
import { formatValue, trim } from "../../helpers";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { warning } from "../../store/slices/messages-slice";
import { getAddresses } from "src/constants";
import { MasterMantisContract, PoolContract, StableReserveContract } from "src/abi";
import { AssetCard } from "./Asset";
import { getLpTokenAddress, getTokenFormatUnit, getTokenPid, mntPerYear } from "src/helpers/get-token-data";
import SwapIcon from "../../assets/icons/swap-arrow.svg";
import SettingIcon from "../../assets/icons/settings.svg";
import USDCIcon from "../../assets/tokens/USDC.svg";
import USDTIcon from "../../assets/tokens/USDT.svg";
import DAIIcon from "../../assets/tokens/DAI.svg";
import CloseIcon from "../../assets/icons/circle-xmark.svg";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function Pool() {
    const dispatch = useDispatch();
    const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();
    const addresses = getAddresses(chainID);

    const [rate, setRate] = useState<string>("");

    const [quantityFrom, setQuantityFrom] = useState<string>("");
    const [quantityTo, setQuantityTo] = useState<string>("");
    const [tokenFrom, setTokenFrom] = useState<string>("");
    const [tokenTo, setTokenTo] = useState<string>("");
    const [fromUnstakedBalance, setFromUnstakedBalance] = useState<string>("");
    const [fromStakedBalance, setFromStakedBalance] = useState<string>("");
    const [toUnstakedBalance, setToUnstakedBalance] = useState<string>("");
    const [toStakedBalance, setToStakedBalance] = useState<string>("");
    const [fromApr, setFromApr] = useState<string>("");
    const [toApr, setToApr] = useState<string>("");

    const [updateTokenDeposits, setUpdateTokenDeposits] = useState<string>("");

    const [triggerPendingMntClaim, setTriggerPendingMntClaim] = useState<boolean>(false);

    const [usdcPendingMnt, setUsdcPendingMnt] = useState<string>("");
    const [usdtPendingMnt, setUsdtPendingMnt] = useState<string>("");
    const [daiPendingMnt, setDaiPendingMnt] = useState<string>("");

    const [usdcAllocation, setUsdcAllocation] = useState<number>(0);
    const [usdtAllocation, setUsdtAllocation] = useState<number>(0);
    const [daiAllocation, setDaiAllocation] = useState<number>(0);

    const [usdcTotalDeposits, setUsdcTotalDeposits] = useState<number>(0);
    const [usdtTotalDeposits, setUsdtTotalDeposits] = useState<number>(0);
    const [daiTotalDeposits, setDaiTotalDeposits] = useState<number>(0);

    const [usdcTotalFactor, setUsdcTotalFactor] = useState<number>(0);
    const [usdtTotalFactor, setUsdtTotalFactor] = useState<number>(0);
    const [daiTotalFactor, setDaiTotalFactor] = useState<number>(0);

    const [slippage, setSlippage] = useState<string>(localStorage.getItem("pool_slippage") || "0.1");
    const [deadline, setDeadline] = useState<string>(localStorage.getItem("pool_deadline") || "5");

    const [oneTapOpen, setOneTapOpen] = useState<boolean>(false);
    const [settingOpen, setSettingOpen] = useState<boolean>(false);

    const poolContract = new ethers.Contract(addresses.POOL_ADDRESS, PoolContract, provider);

    const price = 0.1;

    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
    const lp_usdc_balance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.lp_usdc;
    });
    const lp_usdt_balance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.lp_usdt;
    });
    const lp_dai_balance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.lp_dai;
    });
    const staked_lp_usdc_balance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.staked_lp_usdc;
    });
    const staked_lp_usdt_balance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.staked_lp_usdt;
    });
    const staked_lp_dai_balance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.staked_lp_dai;
    });
    const vemntBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.vemnt;
    });
    const lp_usdc_allowance = useSelector<IReduxState, number>(state => {
        return state.account.allowance_pool && state.account.allowance_pool.lp_usdc;
    });
    const lp_usdt_allowance = useSelector<IReduxState, number>(state => {
        return state.account.allowance_pool && state.account.allowance_pool.lp_usdt;
    });
    const lp_dai_allowance = useSelector<IReduxState, number>(state => {
        return state.account.allowance_pool && state.account.allowance_pool.lp_dai;
    });
    const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
        return state.pendingTransactions;
    });

    const userFactor = (amount: number): number => {
        return amount * (1 + Math.sqrt(Number(vemntBalance)) / 1000);
    };

    const setPositiveSlippage = (value: string) => {
        if (Number(value) >= 0 && Number(value) <= 100) {
            setSlippage(value);
            localStorage.setItem("pool_slippage", value);
        } else {
            setSlippage("0.1");
        }
    };

    const setLimitedDeadline = (value: string) => {
        if (Number(value) > 0 && Number(value) < 8640000) {
            const roundedValue = Number(value).toFixed(0).toString();
            setDeadline(roundedValue);
            localStorage.setItem("pool_deadline", roundedValue);
        } else {
            setDeadline("5");
        }
    };

    const totalDeposits = (): string => {
        return (
            Number(lp_usdc_balance) +
            Number(lp_usdt_balance) +
            Number(lp_dai_balance) +
            Number(staked_lp_usdc_balance) +
            Number(staked_lp_usdt_balance) +
            Number(staked_lp_dai_balance)
        ).toString();
    };

    const totalStaked = (): string => {
        return (Number(staked_lp_usdc_balance) + Number(staked_lp_usdt_balance) + Number(staked_lp_dai_balance)).toString();
    };

    const setMax = () => {
        if (tokenFrom == "usdc") setQuantityFrom(String(Math.max(Number(lp_usdc_balance), Number(staked_lp_usdc_balance))));
        else if (tokenFrom == "usdt") setQuantityFrom(String(Math.max(Number(lp_usdt_balance), Number(staked_lp_usdt_balance))));
        else if (tokenFrom == "dai") setQuantityFrom(String(Math.max(Number(lp_dai_balance), Number(staked_lp_dai_balance))));
    };

    const setPositiveQuantityFrom = (value: string) => {
        Number(value) > 0 ? setQuantityFrom(value) : setQuantityFrom("");
    };

    const getTokenDeposits = async (tokens: any) => {
        if (tokens.includes("usdc")) {
            const lp_usdcContract = new ethers.Contract(addresses.ASSET_USDC_ADDRESS, StableReserveContract, provider);
            const usdcDeposits = await lp_usdcContract.balanceOf(addresses.MASTER_MANTIS_ADDRESS);
            setUsdcTotalDeposits(Number(ethers.utils.formatUnits(usdcDeposits, "mwei")));
        }
        if (tokens.includes("usdt")) {
            const lp_usdtContract = new ethers.Contract(addresses.ASSET_USDT_ADDRESS, StableReserveContract, provider);
            const usdtDeposits = await lp_usdtContract.balanceOf(addresses.MASTER_MANTIS_ADDRESS);
            setUsdtTotalDeposits(Number(ethers.utils.formatUnits(usdtDeposits, "mwei")));
        }
        if (tokens.includes("dai")) {
            const lp_daiContract = new ethers.Contract(addresses.ASSET_DAI_ADDRESS, StableReserveContract, provider);
            const daiDeposits = await lp_daiContract.balanceOf(addresses.MASTER_MANTIS_ADDRESS);
            setDaiTotalDeposits(Number(ethers.utils.formatUnits(daiDeposits, "ether")));
        }
    };

    const getPoolData = async () => {
        const masterContract = new ethers.Contract(addresses.MASTER_MANTIS_ADDRESS, MasterMantisContract, provider);
        const usdcData = await masterContract.poolInfo(getTokenPid("usdc"));
        const usdtData = await masterContract.poolInfo(getTokenPid("usdt"));
        const daiData = await masterContract.poolInfo(getTokenPid("dai"));

        const usdcAllocPoint = Number(ethers.utils.formatUnits(usdcData.allocPoint, "mwei"));
        const usdtAllocPoint = Number(ethers.utils.formatUnits(usdcData.allocPoint, "mwei"));
        const daiAllocPoint = Number(ethers.utils.formatUnits(usdcData.allocPoint, "mwei"));

        const totalAllocPoint = usdcAllocPoint + usdtAllocPoint + daiAllocPoint;

        setUsdcAllocation((usdcAllocPoint * mntPerYear * price) / totalAllocPoint);
        setUsdtAllocation((usdtAllocPoint * mntPerYear * price) / totalAllocPoint);
        setDaiAllocation((daiAllocPoint * mntPerYear * price) / totalAllocPoint);

        setUsdcTotalFactor(Number(ethers.utils.formatUnits(usdcData.totalRewardFactor, "mwei")));
        setUsdtTotalFactor(Number(ethers.utils.formatUnits(usdtData.totalRewardFactor, "mwei")));
        setDaiTotalFactor(Number(ethers.utils.formatUnits(daiData.totalRewardFactor, "ether")));

        await getTokenDeposits(["usdc", "usdt", "dai"]);
    };

    useEffect(() => {
        if (address) {
            getPoolData();
        }
    }, []);

    useEffect(() => {
        if (updateTokenDeposits != "") {
            getTokenDeposits(updateTokenDeposits);
            setUpdateTokenDeposits("");
        }
    }, [updateTokenDeposits]);

    const onSeekApproval = async (token: string) => {
        if (await checkWrongNetwork()) return;

        await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
    };

    const onOneTap = async (staked: boolean) => {
        if (await checkWrongNetwork()) return;
        if (quantityFrom === "" || parseFloat(quantityFrom) === 0) {
            dispatch(warning({ text: "Please enter a valid amount" }));
        } else {
            await dispatch(
                oneTap({
                    address,
                    tokenFrom,
                    tokenTo,
                    value: String(quantityFrom),
                    staked,
                    provider,
                    networkID: chainID,
                }),
            );
            setQuantityFrom("");
        }
    };

    const onClaim = async (action: string) => {
        if (await checkWrongNetwork()) return;
        await dispatch(claim({ address, action, provider, networkID: chainID }));
        setTriggerPendingMntClaim(!triggerPendingMntClaim); // toggle
    };

    const hasAllowance = useCallback(
        token => {
            if (token === "usdc") return lp_usdc_allowance > 0;
            else if (token === "usdt") return lp_usdt_allowance > 0;
            else if (token === "dai") return lp_dai_allowance > 0;
            return 0;
        },
        [lp_usdc_allowance, lp_usdt_allowance, lp_dai_allowance],
    );

    useEffect(() => {
        if (tokenFrom === "usdc") {
            setFromApr(trim(((usdcAllocation * userFactor(Number(quantityFrom))) / usdcTotalFactor) * 100, 1));
        } else if (tokenFrom === "usdt") {
            setFromApr(trim(((usdtAllocation * userFactor(Number(quantityFrom))) / usdtTotalFactor) * 100, 1));
        } else if (tokenFrom === "dai") {
            setFromApr(trim(((daiAllocation * userFactor(Number(quantityFrom))) / daiTotalFactor) * 100, 1));
        }
    }, [tokenFrom, quantityFrom]);

    useEffect(() => {
        if (tokenTo === "usdc") {
            setToApr(trim(((usdcAllocation * userFactor(Number(quantityFrom))) / usdcTotalFactor) * 100, 1));
        } else if (tokenTo === "usdt") {
            setToApr(trim(((usdtAllocation * userFactor(Number(quantityFrom))) / usdtTotalFactor) * 100, 1));
        } else if (tokenTo === "dai") {
            setToApr(trim(((daiAllocation * userFactor(Number(quantityFrom))) / daiTotalFactor) * 100, 1));
        }
    }, [tokenTo, quantityTo]);

    useEffect(() => {
        if (tokenFrom === "usdc") {
            setFromUnstakedBalance(trim(Number(lp_usdc_balance), 2));
            setFromStakedBalance(trim(Number(staked_lp_usdc_balance), 2));
        } else if (tokenFrom === "usdt") {
            setFromUnstakedBalance(trim(Number(lp_usdt_balance), 2));
            setFromStakedBalance(trim(Number(staked_lp_usdt_balance), 2));
        } else if (tokenFrom === "dai") {
            setFromUnstakedBalance(trim(Number(lp_dai_balance), 2));
            setFromStakedBalance(trim(Number(staked_lp_dai_balance), 2));
        }
    }, [tokenFrom, lp_usdc_balance, staked_lp_usdc_balance, lp_usdt_balance, staked_lp_usdt_balance, lp_dai_balance, staked_lp_dai_balance]);

    useEffect(() => {
        if (tokenTo === "usdc") {
            setToUnstakedBalance(trim(Number(lp_usdc_balance), 2));
            setToStakedBalance(trim(Number(staked_lp_usdc_balance), 2));
        } else if (tokenTo === "usdt") {
            setToUnstakedBalance(trim(Number(lp_usdt_balance), 2));
            setToStakedBalance(trim(Number(staked_lp_usdt_balance), 2));
        } else if (tokenTo === "dai") {
            setToUnstakedBalance(trim(Number(lp_dai_balance), 2));
            setToStakedBalance(trim(Number(staked_lp_dai_balance), 2));
        }
    }, [tokenTo, lp_usdc_balance, staked_lp_usdc_balance, lp_usdt_balance, staked_lp_usdt_balance, lp_dai_balance, staked_lp_dai_balance]);

    useEffect(() => {
        const timeOutId = setTimeout(() => quantityFromChange(quantityFrom), 250);
        return () => clearTimeout(timeOutId);
    }, [tokenFrom, quantityFrom]);

    const switchToken = () => {
        const oldTokenFrom = tokenFrom;
        setTokenFrom(tokenTo);
        setTokenTo(oldTokenFrom);
    };

    async function quantityFromChange(value: string) {
        let toAmount = 0;
        const tokenFromAddress = getLpTokenAddress(chainID, tokenFrom);
        const tokenToAddress = getLpTokenAddress(chainID, tokenTo);
        if (Number(value) > 0 && tokenFromAddress != "" && tokenToAddress != "") {
            const formatUnit = getTokenFormatUnit(tokenFrom);
            const contractValue = ethers.utils.parseUnits(value, formatUnit);
            const quoteData = await poolContract.getOneTapAmount(tokenFromAddress, tokenToAddress, contractValue);
            toAmount = Number(ethers.utils.formatUnits(quoteData.depositLpAmount, formatUnit));
            const oneTapRate = trim(toAmount / Number(value), 2);
            setRate(oneTapRate);
            setQuantityTo(String(toAmount));
        } else {
            setQuantityTo("");
        }
    }

    const handleOneTapOpen = () => setOneTapOpen(true);
    const handleOneTapClose = () => setOneTapOpen(false);

    const handleSettingOpen = () => setSettingOpen(true);
    const handleSettingClose = () => setSettingOpen(false);

    return (
        <div className="pool-view">
            <Zoom in={true}>
                <div className="pool-card mb-3">
                    <div className="pool-card-area">
                        {!address && (
                            <div className="pool-card-wallet-notification">
                                <div className="pool-card-wallet-connect-btn" onClick={connect}>
                                    <p>Connect Wallet</p>
                                </div>
                                <p className="pool-card-wallet-desc-text">Connect your wallet to provide liquidity and get MNT tokens!</p>
                            </div>
                        )}
                        {address && (
                            <div>
                                <div className="pool-card-header">
                                    <p className="pool-card-header-title">Stablecoin Pool</p>
                                    <img className="setting-icon" alt="" src={SettingIcon} width={28} height={28} onClick={handleSettingOpen} />
                                </div>
                                <div className="pool-user-data">
                                    <Grid container spacing={3} alignItems="stretch">
                                        <Grid item xs={12} sm={4}>
                                            <div className="user-data-row">
                                                <p className="user-data-row-name">TOTAL DEPOSITS</p>
                                                <p className="user-data-row-value">{formatValue(totalDeposits())} LP</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div className="user-data-row">
                                                <p className="user-data-row-name">TOTAL STAKED</p>
                                                <p className="user-data-row-value">{formatValue(totalStaked())}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <div className="user-data-row">
                                                <p className="user-data-row-name">MNT EARNED</p>
                                                <p className="user-data-row-value">{trim(Number(usdcPendingMnt) + Number(usdtPendingMnt) + Number(daiPendingMnt), 2)} MNT</p>
                                                <Button
                                                    className="user-data-row-btn"
                                                    variant="contained"
                                                    fullWidth={true}
                                                    onClick={() => {
                                                        if (isPendingTxn(pendingTransactions, "claim_all")) return;
                                                        onClaim("all");
                                                    }}
                                                >
                                                    {txnButtonText(pendingTransactions, "claim_all", "Claim All")}
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Grid className="pool-card-grid" container spacing={2}>
                                    {/* <Grid item xs={12} md={12}>
                                        <div className="pool-card-item">
                                            <Button variant="outlined" color="primary" onClick={handleOneTapOpen} fullWidth={true} className="pool-card-item-btn">
                                                One Tap
                                            </Button>
                                        </div>
                                    </Grid> */}
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            {address && (
                                                <AssetCard
                                                    token="usdc"
                                                    ASSET_ADDRESS={addresses.ASSET_USDC_ADDRESS}
                                                    allocation={usdcAllocation}
                                                    totalFactor={usdcTotalFactor}
                                                    triggerUpdateDeposits={setUpdateTokenDeposits}
                                                    setPendingMnt={setUsdcPendingMnt}
                                                    triggerPendingMntClaim={triggerPendingMntClaim}
                                                    slippage={slippage}
                                                    deadline={deadline}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            {address && (
                                                <AssetCard
                                                    token="usdt"
                                                    ASSET_ADDRESS={addresses.ASSET_USDT_ADDRESS}
                                                    allocation={usdtAllocation}
                                                    totalFactor={usdtTotalFactor}
                                                    triggerUpdateDeposits={setUpdateTokenDeposits}
                                                    setPendingMnt={setUsdtPendingMnt}
                                                    triggerPendingMntClaim={triggerPendingMntClaim}
                                                    slippage={slippage}
                                                    deadline={deadline}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <div>
                                            {address && (
                                                <AssetCard
                                                    token="dai"
                                                    ASSET_ADDRESS={addresses.ASSET_DAI_ADDRESS}
                                                    allocation={daiAllocation}
                                                    totalFactor={daiTotalFactor}
                                                    triggerUpdateDeposits={setUpdateTokenDeposits}
                                                    setPendingMnt={setDaiPendingMnt}
                                                    triggerPendingMntClaim={triggerPendingMntClaim}
                                                    slippage={slippage}
                                                    deadline={deadline}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </div>
                </div>
            </Zoom>
            <Modal open={oneTapOpen} onClose={handleOneTapClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="modal-box">
                    <div className="modal-header">
                        <h1 className="modal-title">One Tap</h1>
                        <span className="modal-close" onClick={handleOneTapClose}>
                            X
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="swap-card-action-area bg-primary">
                            <div className="swap-card-action-row">
                                <div className="swap-card-tab-panel">
                                    <div className="swap-card-tab-panel-text-left">From Position</div>
                                </div>
                                {tokenFrom && <div className="swap-card-tab-panel-text-right">Balance: {Number(fromUnstakedBalance) + Number(fromStakedBalance)}</div>}
                            </div>
                            <div className="swap-card-action-row">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Select
                                            disableUnderline={true}
                                            value={tokenFrom}
                                            label="From"
                                            className="swap-card-select-input"
                                            onChange={e => {
                                                const newTokenFrom = e.target.value as string;
                                                if (newTokenFrom === tokenTo) {
                                                    setTokenTo(tokenFrom);
                                                }
                                                setTokenFrom(newTokenFrom);
                                            }}
                                        >
                                            <MenuItem value="">Please Select..</MenuItem>
                                            <MenuItem value="usdc">
                                                <img className="token-img" alt="" src={USDCIcon} width={28} height={28} />
                                                <span className="token-title">USDC</span>
                                            </MenuItem>
                                            <MenuItem value="usdt">
                                                <img className="token-img" alt="" src={USDTIcon} width={28} height={28} />
                                                <span className="token-title">USDT</span>
                                            </MenuItem>
                                            <MenuItem value="dai">
                                                <img className="token-img" alt="" src={DAIIcon} width={28} height={28} />
                                                <span className="token-title">DAI</span>
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutlinedInput
                                            type="number"
                                            placeholder="0.0"
                                            className="swap-card-action-input"
                                            value={quantityFrom}
                                            onChange={e => setPositiveQuantityFrom(e.target.value)}
                                            labelWidth={0}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <div onClick={setMax} className="swap-card-action-input-btn">
                                                        <p>MAX</p>
                                                    </div>
                                                </InputAdornment>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className="swap-card-action-area no-gutters">
                            <div className="swap-card-action-row">
                                <div className="swap-card-tab-panel-center">
                                    <img className="swap-img" alt="" src={SwapIcon} width={40} height={40} onClick={switchToken} />
                                </div>
                            </div>
                        </div>
                        <div className="swap-card-action-area bg-secondary mt-0">
                            <div className="swap-card-action-row">
                                <div className="swap-card-tab-panel">
                                    <div className="swap-card-tab-panel-text-left">To Position</div>
                                </div>
                                {tokenTo && <div className="swap-card-tab-panel-text-right">Balance: {Number(toUnstakedBalance) + Number(toStakedBalance)}</div>}
                            </div>
                            <div className="swap-card-action-row">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Select
                                            disableUnderline={true}
                                            value={tokenTo}
                                            label="To"
                                            className="swap-card-select-input"
                                            onChange={e => setTokenTo(e.target.value as string)}
                                        >
                                            <MenuItem value="">Please Select..</MenuItem>
                                            {tokenFrom !== "usdc" && (
                                                <MenuItem value="usdc">
                                                    <img className="token-img" alt="" src={USDCIcon} width={28} height={28} />
                                                    <span className="token-title">USDC</span>
                                                </MenuItem>
                                            )}
                                            {tokenFrom !== "usdt" && (
                                                <MenuItem value="usdt">
                                                    <img className="token-img" alt="" src={USDTIcon} width={28} height={28} />
                                                    <span className="token-title">USDT</span>
                                                </MenuItem>
                                            )}
                                            {tokenFrom !== "dai" && (
                                                <MenuItem value="dai">
                                                    <img className="token-img" alt="" src={DAIIcon} width={28} height={28} />
                                                    <span className="token-title">DAI</span>
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <OutlinedInput type="number" placeholder="0.0" className="swap-card-action-input" value={quantityTo} labelWidth={0} disabled={true} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">Unstaked {tokenFrom.toUpperCase()} LP</p>
                            <p className="modal-data-row-value">{fromUnstakedBalance}</p>
                        </div>
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">Staked {tokenFrom.toUpperCase()} LP</p>
                            <p className="modal-data-row-value">{fromStakedBalance}</p>
                        </div>
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">Conversion Rate</p>
                            <p className="modal-data-row-value">
                                1 LP-{tokenFrom.toUpperCase()} = {rate} LP-{tokenTo.toUpperCase()}
                            </p>
                        </div>
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">APR Update</p>
                            <p className="modal-data-row-value">
                                {fromApr}% -&gt; {toApr}%
                            </p>
                        </div>
                        {hasAllowance(tokenFrom) ? (
                            <div
                                className="modal-panel-btn"
                                onClick={() => {
                                    if (isPendingTxn(pendingTransactions, "onetap")) return;
                                    onOneTap(false);
                                }}
                            >
                                <p>{txnButtonText(pendingTransactions, "onetap", "One Tap")}</p>
                            </div>
                        ) : (
                            <div
                                className="modal-panel-btn"
                                onClick={() => {
                                    if (isPendingTxn(pendingTransactions, "approve_lp_" + tokenFrom)) return;
                                    onSeekApproval("lp_" + tokenFrom);
                                }}
                            >
                                <p>{txnButtonText(pendingTransactions, "approve_lp_" + tokenFrom, "Approve")}</p>
                            </div>
                        )}
                        <div
                            className="modal-panel-btn"
                            onClick={() => {
                                if (isPendingTxn(pendingTransactions, "onetap")) return;
                                onOneTap(true);
                            }}
                        >
                            <p>{txnButtonText(pendingTransactions, "onetap", "One Tap Stake")}</p>
                        </div>
                    </div>
                </Box>
            </Modal>

            <Modal open={settingOpen} onClose={handleSettingClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="pool-modal-box">
                    <div className="modal-header">
                        <h1 className="modal-title">Deposit / Withdraw Settings</h1>
                        <img className="close-icon modal-close" alt="" src={CloseIcon} width={28} height={28} onClick={handleSettingClose} />
                    </div>
                    <div className="modal-body">
                        <p className="modal-normal-text">Slippage Allowed</p>
                        <RadioGroup row aria-label="slippageTolerance" name="slippage" value={slippage} onChange={e => setPositiveSlippage(e.target.value)} className="radio-input">
                            <FormControlLabel value="0.1" control={<Radio />} label="0.1%" labelPlacement="end" />
                            <FormControlLabel value="0.5" control={<Radio />} label="0.5%" labelPlacement="end" />
                            <FormControlLabel value="1" control={<Radio />} label="1%" labelPlacement="end" />
                        </RadioGroup>
                        <OutlinedInput
                            type="number"
                            placeholder="Slippage %"
                            className="modal-input"
                            value={slippage}
                            onChange={e => setPositiveSlippage(e.target.value)}
                            labelWidth={0}
                        />
                        <p className="modal-normal-text mt-3">Transaction Deadline</p>
                        <RadioGroup
                            row
                            aria-label="transactionDeadline"
                            name="deadline"
                            value={deadline}
                            onChange={e => setLimitedDeadline(e.target.value)}
                            className="radio-input"
                        >
                            <FormControlLabel value="1" control={<Radio />} label="1 minute" labelPlacement="end" />
                            <FormControlLabel value="5" control={<Radio />} label="5 minutes" labelPlacement="end" />
                            <FormControlLabel value="30" control={<Radio />} label="30 minutes" labelPlacement="end" />
                        </RadioGroup>
                        <OutlinedInput
                            type="number"
                            placeholder="Deadline"
                            className="modal-input"
                            value={deadline}
                            onChange={e => setLimitedDeadline(e.target.value)}
                            labelWidth={0}
                        />
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
export default Pool;
