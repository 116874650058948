import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "src/store/slices/state.interface";
import { changeApproval, deposit, withdraw, stake, unstake, claim } from "src/store/slices/pool-thunk";
import { useState, useCallback, useEffect } from "react";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { formatValue, formatValueDigits, getTokenSVG, trim } from "../../helpers";
import { useWeb3Context } from "../../hooks";
import { Skeleton } from "@material-ui/lab";
import classnames from "classnames";
import { warning } from "../../store/slices/messages-slice";
import { AssetContract, MasterMantisContract, PoolContract } from "src/abi";
import { Box, Grid, InputAdornment, Modal, OutlinedInput, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import ChevronDownIcon from "../../assets/icons/chevron-down-solid.svg";
import ChevronUpIcon from "../../assets/icons/chevron-up-solid.svg";
import CloseIcon from "../../assets/icons/circle-xmark.svg";
import { getAddresses } from "src/constants";
import { getTokenFormatUnit, getTokenPid } from "src/helpers/get-token-data";
import { fetchAccountSuccess } from "src/store/slices/account-slice";
import InfoIcon from "../../assets/icons/info-circle.svg";

interface IAssetProps {
    token: string;
    ASSET_ADDRESS: string;
    allocation: number;
    totalFactor: number;
    triggerUpdateDeposits: any;
    setPendingMnt: any;
    triggerPendingMntClaim: any;
    slippage: string;
    deadline: string;
}

const GradientButton = withStyles(theme => ({
    root: {
        color: "#ffffff",
        borderRadius: "32px",
        fontFamily: "Proxima",
        fontWeight: 600,
        textTransform: "none",
        marginTop: "1rem",
        background: "linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(177,157,255,1) 100%)",
        "&:hover": {
            background: "linear-gradient(90deg, rgba(56,84,225,1) 0%, rgba(159,141,229,1) 100%)",
        },
    },
}))(Button);

export function AssetCard({ token, ASSET_ADDRESS, allocation, totalFactor, triggerUpdateDeposits, setPendingMnt, triggerPendingMntClaim, slippage, deadline }: IAssetProps) {
    const dispatch = useDispatch();
    const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();
    const addresses = getAddresses(chainID);
    const lp_string = "lp_" + token;
    const staked_lp_string = "staked_lp_" + token;

    const [showStaking, setShowStaking] = useState<boolean>(false);
    const [view, setView] = useState(0);

    const [depositQuantity, setDepositQuantity] = useState<string>("");
    const [withdrawQuantity, setWithdrawQuantity] = useState<string>("");
    const [stakeQuantity, setStakeQuantity] = useState<string>("");
    const [unstakeQuantity, setUnstakeQuantity] = useState<string>("");

    const [cash, setCash] = useState<string>("");
    const [liability, setLiability] = useState<string>("");
    const [cov, setCov] = useState<string>("");

    const [depositFees, setDepositFees] = useState<string>("");
    const [withdrawFees, setWithdrawFees] = useState<string>("");

    const [userFactor, setUserFactor] = useState<string>("");

    const [depositOpen, setDepositOpen] = useState<boolean>(false);
    const [withdrawOpen, setWithdrawOpen] = useState<boolean>(false);

    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
    const balance = useSelector<IReduxState, string>(state => {
        // @ts-ignore
        return state.account.balances && state.account.balances[token];
    });
    const lp_balance = useSelector<IReduxState, string>(state => {
        // @ts-ignore
        return state.account.balances && state.account.balances[lp_string];
    });
    const staked_lp_balance = useSelector<IReduxState, string>(state => {
        // @ts-ignore
        return state.account.balances && state.account.balances[staked_lp_string];
    });
    const pendingPtp = useSelector<IReduxState, string>(state => {
        // @ts-ignore
        return state.account.claim && state.account.claim[lp_string];
    });
    const allowance = useSelector<IReduxState, number>(state => {
        // @ts-ignore
        return state.account.allowance_pool && state.account.allowance_pool[token];
    });
    const lp_allowance = useSelector<IReduxState, number>(state => {
        // @ts-ignore
        return state.account.allowance_pool && state.account.allowance_pool[lp_string];
    });
    const staked_lp_allowance = useSelector<IReduxState, number>(state => {
        // @ts-ignore
        return state.account.allowance_master && state.account.allowance_master[lp_string];
    });
    const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
        return state.pendingTransactions;
    });

    const setDepositMax = () => {
        setDepositQuantity(balance);
    };

    const setWithdrawMax = () => {
        setWithdrawQuantity(lp_balance);
    };

    const setStakeMax = () => {
        setStakeQuantity(lp_balance);
    };

    const setUnstakeMax = () => {
        setUnstakeQuantity(staked_lp_balance);
    };

    const setPositiveStakeQuantity = (value: string) => {
        Number(value) >= 0 ? setStakeQuantity(value) : setStakeQuantity("");
    };

    const setPositiveUnstakeQuantity = (value: string) => {
        Number(value) >= 0 ? setUnstakeQuantity(value) : setUnstakeQuantity("");
    };

    const setPositiveDepositQuantity = (value: string) => {
        if (Number(value) > Number(balance)) value = balance;
        Number(value) >= 0 ? setDepositQuantity(value) : setDepositQuantity("");
    };

    const setPositiveWithdrawQuantity = (value: string) => {
        if (Number(value) > Number(lp_balance)) value = lp_balance;
        Number(value) >= 0 ? setWithdrawQuantity(value) : setWithdrawQuantity("");
    };

    const onSeekApproval = async (token: string) => {
        if (await checkWrongNetwork()) return;
        await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
    };

    const onDeposit = async (action: string, autoStake: boolean) => {
        if (await checkWrongNetwork()) return;
        if (depositQuantity === "" || parseFloat(depositQuantity) === 0) {
            dispatch(warning({ text: "Please enter a valid amount" }));
        } else {
            await dispatch(deposit({ address, action, value: String(depositQuantity), autoStake, deadline, provider, networkID: chainID }));
            setDepositQuantity("");
            getUserBalance();
            getAssetLiability();
            triggerUpdateDeposits(token);
            if (autoStake) {
                getPendingMnt();
            }
        }
    };

    const onWithdraw = async (action: string) => {
        if (await checkWrongNetwork()) return;
        if (withdrawQuantity === "" || parseFloat(withdrawQuantity) === 0) {
            dispatch(warning({ text: "Please enter a valid amount" }));
        } else {
            await dispatch(withdraw({ address, action, value: String(withdrawQuantity), slippage, deadline, provider, networkID: chainID }));
            setWithdrawQuantity("");
            getUserBalance();
            getAssetLiability();
            triggerUpdateDeposits(token);
        }
    };

    const onStake = async (action: string) => {
        if (await checkWrongNetwork()) return;
        if (stakeQuantity === "" || parseFloat(stakeQuantity) === 0) {
            dispatch(warning({ text: "Please enter a valid amount" }));
        } else {
            await dispatch(stake({ address, action, value: String(stakeQuantity), provider, networkID: chainID }));
            setStakeQuantity("");
            getUserBalance();
            getPendingMnt();
        }
    };

    const onUnstake = async (action: string) => {
        if (await checkWrongNetwork()) return;
        if (unstakeQuantity === "" || parseFloat(unstakeQuantity) === 0) {
            dispatch(warning({ text: "Please enter a valid amount" }));
        } else {
            await dispatch(unstake({ address, action, value: String(unstakeQuantity), provider, networkID: chainID }));
            setUnstakeQuantity("");
            getUserBalance();
        }
    };

    const onClaim = async (action: string) => {
        if (await checkWrongNetwork()) return;
        await dispatch(claim({ address, action, provider, networkID: chainID }));
        getPendingMnt();
    };

    const hasAllowance = useCallback(
        tokenString => {
            if (tokenString === token) return allowance > 0;
            else if (tokenString === lp_string) return lp_allowance > 0;
            else if (tokenString === staked_lp_string) return staked_lp_allowance > 0;
            return 0;
        },
        [allowance, lp_allowance, staked_lp_allowance],
    );

    useEffect(() => {
        const getDepositFees = async () => {
            const poolContract = new ethers.Contract(addresses.POOL_ADDRESS, PoolContract, provider);
            const formatUnit = getTokenFormatUnit(token);

            if (Number(depositQuantity) > 0) {
                const amount = ethers.utils.parseUnits(depositQuantity, formatUnit);
                const depositData = await poolContract.getDepositAmount(ASSET_ADDRESS, amount, false, 0);
                setDepositFees(ethers.utils.formatUnits(depositData.fees, formatUnit));
            } else {
                setDepositFees("0");
            }
        };
        getDepositFees();
    }, [depositQuantity]);

    useEffect(() => {
        const getWithdrawFees = async () => {
            const poolContract = new ethers.Contract(addresses.POOL_ADDRESS, PoolContract, provider);
            const formatUnit = getTokenFormatUnit(token);

            if (Number(withdrawQuantity) > 0) {
                try {
                    const amount = ethers.utils.parseUnits(withdrawQuantity, formatUnit);
                    const withdrawData = await poolContract.getWithdrawAmount(ASSET_ADDRESS, amount, false);
                    setWithdrawFees(ethers.utils.formatUnits(withdrawData.fees, formatUnit));
                } catch (e: any) {
                    setWithdrawFees("0");
                }
            } else {
                setWithdrawFees("0");
            }
        };
        getWithdrawFees();
    }, [withdrawQuantity]);

    const getUserBalance = async () => {
        const lp_contract = new ethers.Contract(ASSET_ADDRESS, AssetContract, provider);
        const masterPlatypusContract = new ethers.Contract(addresses.MASTER_MANTIS_ADDRESS, MasterMantisContract, provider);
        const tokenPid = getTokenPid(token);
        const formatUnit = getTokenFormatUnit(token);

        let balanceObj: any = {};
        const lpBalance = await lp_contract.balanceOf(address);
        balanceObj[lp_string] = ethers.utils.formatUnits(lpBalance, formatUnit);
        const staked_lp_data = await masterPlatypusContract.userInfo(tokenPid, address);
        const staked_lpBalance = staked_lp_data.amount;
        setUserFactor(ethers.utils.formatUnits(staked_lp_data.rewardFactor, formatUnit));
        balanceObj[staked_lp_string] = ethers.utils.formatUnits(staked_lpBalance, formatUnit);
        dispatch(
            fetchAccountSuccess({
                balances: balanceObj,
            }),
        );
    };

    const getAssetLiability = async () => {
        const lp_contract = new ethers.Contract(ASSET_ADDRESS, AssetContract, provider);
        const formatUnit = getTokenFormatUnit(token);
        const tokenAsset = Number(ethers.utils.formatUnits(await lp_contract.asset(), formatUnit));
        setCash(trim(tokenAsset, 2));
        const tokenLiability = Number(ethers.utils.formatUnits(await lp_contract.liability(), formatUnit));
        setLiability(trim(tokenLiability, 2));
        setCov(trim((tokenAsset / tokenLiability) * 100, 2));
    };

    const getAllowance = async () => {
        const lp_contract = new ethers.Contract(ASSET_ADDRESS, AssetContract, provider);

        let allowance_pool_obj: any = {};
        let allowance_master_obj: any = {};
        const lpAllowance = await lp_contract.allowance(address, addresses.POOL_ADDRESS);
        allowance_pool_obj[lp_string] = lpAllowance;
        const masterlp_Allowance = await lp_contract.allowance(address, addresses.MASTER_MANTIS_ADDRESS);
        allowance_master_obj[lp_string] = masterlp_Allowance;

        dispatch(
            fetchAccountSuccess({
                allowance_pool: allowance_pool_obj,
                allowance_master: allowance_master_obj,
            }),
        );
    };

    const getPendingMnt = async () => {
        const masterPlatypusContract = new ethers.Contract(addresses.MASTER_MANTIS_ADDRESS, MasterMantisContract, provider);
        const tokenPid = getTokenPid(token);

        let claim_obj: any = {};
        const pendingPtp_lp_data = await masterPlatypusContract.pendingMnt(tokenPid, address);
        const pendingPtp_lp = pendingPtp_lp_data.pendingMNT;
        const pendingAmount = ethers.utils.formatUnits(pendingPtp_lp, "ether");
        claim_obj[lp_string] = pendingAmount;
        setPendingMnt(pendingAmount);

        dispatch(
            fetchAccountSuccess({
                claim: claim_obj,
            }),
        );
    };

    const getPoolData = async () => {
        if (address) {
            getUserBalance();
            getAssetLiability();
            getAllowance();
            getPendingMnt();
        }
    };

    useEffect(() => {
        getPoolData();
    }, []);

    useEffect(() => {
        getPendingMnt();
    }, [triggerPendingMntClaim]);

    const changeView = (newView: number) => () => {
        setView(newView);
    };

    const toggleShowStaking = () => {
        setShowStaking(!showStaking);
    };

    const getDepositPoolShare = () => {
        return trim(((Number(lp_balance) + Number(depositQuantity) - Number(depositFees)) * 100) / (Number(liability) + Number(depositQuantity) - Number(depositFees)), 2);
    };

    const getWithdrawPoolShare = () => {
        return trim(((Number(lp_balance) - Number(withdrawQuantity)) * 100) / (Number(liability) - Number(withdrawQuantity)), 2);
    };

    const tokenName = token.toUpperCase();
    const lpTokenName = "LP-" + tokenName;
    const handleDepositOpen = () => setDepositOpen(true);
    const handleDepositClose = () => setDepositOpen(false);
    const handleWithdrawOpen = () => setWithdrawOpen(true);
    const handleWithdrawClose = () => setWithdrawOpen(false);

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: "rgba(11, 0, 30, 1)",
        },
        tooltip: {
            backgroundColor: "rgba(11, 0, 30, 1)",
            fontSize: 11,
            fontStyle: "Proxima",
        },
    }))(Tooltip);

    return (
        <div className="pool-card-item">
            <div className="pool-card-item-header">
                <img src={getTokenSVG(token)} width={48} height={48} />
                <span className="pool-card-item-header-title">{tokenName}</span>
                {Number(staked_lp_balance) > 0 && <Chip className="pool-card-item-header-label" label="Earning" />}
            </div>
            <div className="pool-user-data">
                <div className="data-row">
                    <p className="data-row-name">LIQUIDITY RATIO</p>
                    <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>{cov} %</>}</p>
                </div>
                <Divider className="underline" />
                <div className="data-row">
                    <p className="data-row-name">POOL TVL</p>
                    <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>${trim(Number(liability) / 1000000, 2)} M</>}</p>
                </div>
                <Divider className="underline" />
                <div className="data-row">
                    <p className="data-row-name">DEPOSITS</p>
                    <p className="data-row-value">
                        {isAppLoading ? <Skeleton width="80px" /> : <>{formatValue((Number(lp_balance) + Number(staked_lp_balance)).toString())} LP</>}
                    </p>
                </div>
                <Divider className="underline" />
                <div className="data-row">
                    <p className="data-row-name">STAKED</p>
                    <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>{formatValue(staked_lp_balance)} LP</>}</p>
                </div>
            </div>
            <div className="pool-card-action-area">
                <div className="pool-card-action-row">
                    <Grid className="pool-card-grid" container spacing={1}>
                        <Grid item xs={6}>
                            <Button variant="outlined" color="primary" onClick={handleDepositOpen} fullWidth={true} className="pool-card-item-btn">
                                DEPOSIT
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="outlined" onClick={handleWithdrawOpen} fullWidth={true} className="pool-card-item-btn">
                                WITHDRAW
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="pool-apr-data">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <div className="data-row-apr">
                            <p className="data-row-name">Current APR</p>
                            <p className="data-row-value">{totalFactor > 0 ? trim((allocation / totalFactor) * 100, 1) : "-"} %</p>
                        </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <div className="data-row-apr">
                            <p className="data-row-name">My APR</p>
                            <p className="data-row-value">
                                {Number(userFactor) > 0 ? trim(((allocation * Number(userFactor)) / (totalFactor * Number(staked_lp_balance))) * 100, 1) : "-"} %
                            </p>
                        </div>
                    </Grid> */}
                </Grid>
            </div>
            {/* <Divider /> */}
            <div className="pool-card-action-area mt-0">
                <div className="pool-card-action-row mt-1" onClick={toggleShowStaking}>
                    <Button fullWidth={true} className="pool-card-item-btn collapse-btn">
                        Staking
                        <img alt="" src={showStaking ? ChevronUpIcon : ChevronDownIcon} width={12} height={12} />
                    </Button>
                </div>
                {showStaking && (
                    <>
                        <div className="pool-card-action-stage-btns-wrap">
                            <div onClick={changeView(0)} className={classnames("pool-card-action-stage-btn", { active: !view })}>
                                <p>STAKE</p>
                            </div>
                            <div onClick={changeView(1)} className={classnames("pool-card-action-stage-btn", { active: view == 1 })}>
                                <p>UNSTAKE</p>
                            </div>
                            <div onClick={changeView(2)} className={classnames("pool-card-action-stage-btn", { active: view == 2 })}>
                                <p>CLAIM</p>
                            </div>
                        </div>
                        {view == 0 && (
                            <div className="pool-card-action-row staking-data">
                                <div className="staking-data-row">
                                    <p className="staking-data-row-name">AVAILABLE</p>
                                    <p className="staking-data-row-value">
                                        {formatValue(lp_balance)} {lpTokenName}
                                    </p>
                                </div>
                                <OutlinedInput
                                    type="number"
                                    placeholder="Amount"
                                    className="pool-card-action-input"
                                    value={stakeQuantity}
                                    onChange={e => setPositiveStakeQuantity(e.target.value)}
                                    labelWidth={0}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <div onClick={setStakeMax} className="pool-card-action-input-btn">
                                                <p>MAX</p>
                                            </div>
                                        </InputAdornment>
                                    }
                                />
                                {hasAllowance(staked_lp_string) ? (
                                    <GradientButton
                                        variant="contained"
                                        fullWidth={true}
                                        onClick={() => {
                                            if (isPendingTxn(pendingTransactions, "stake_" + token)) return;
                                            onStake(token);
                                        }}
                                    >
                                        {txnButtonText(pendingTransactions, "stake_" + token, "Stake LP")}
                                    </GradientButton>
                                ) : (
                                    <Button
                                        className="pool-card-item-btn approve-action"
                                        variant="outlined"
                                        fullWidth={true}
                                        onClick={() => {
                                            if (isPendingTxn(pendingTransactions, "approve_" + staked_lp_string)) return;
                                            onSeekApproval(staked_lp_string);
                                        }}
                                    >
                                        {txnButtonText(pendingTransactions, "approve_" + staked_lp_string, "Approve Stake")}
                                    </Button>
                                )}
                            </div>
                        )}
                        {view == 1 && (
                            <div className="pool-card-action-row staking-data">
                                <div className="staking-data-row">
                                    <p className="staking-data-row-name">STAKED</p>
                                    <p className="staking-data-row-value">
                                        {formatValue(staked_lp_balance)} {lpTokenName}
                                    </p>
                                </div>
                                <OutlinedInput
                                    type="number"
                                    placeholder="Amount"
                                    className="pool-card-action-input"
                                    value={unstakeQuantity}
                                    onChange={e => setPositiveUnstakeQuantity(e.target.value)}
                                    labelWidth={0}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <div onClick={setUnstakeMax} className="pool-card-action-input-btn">
                                                <p>MAX</p>
                                            </div>
                                        </InputAdornment>
                                    }
                                />
                                <Button
                                    className="pool-card-item-btn"
                                    variant="outlined"
                                    fullWidth={true}
                                    onClick={() => {
                                        if (isPendingTxn(pendingTransactions, "unstake_" + token)) return;
                                        onUnstake(token);
                                    }}
                                >
                                    {txnButtonText(pendingTransactions, "unstake_" + token, "Unstake LP")}
                                </Button>
                                {/* <div
                                    className="modal-panel-btn"
                                    onClick={() => {
                                        if (isPendingTxn(pendingTransactions, "unstake_" + token)) return;
                                        onUnstake(token);
                                    }}
                                >
                                    <p>{txnButtonText(pendingTransactions, "unstake_" + token, "Unstake LP")}</p>
                                </div> */}
                            </div>
                        )}
                        {view == 2 && (
                            <div className="pool-user-data">
                                <div className="data-row">
                                    <p className="data-row-name">Earned</p>
                                    <p className="data-row-value">{formatValue(pendingPtp)} MNT</p>
                                </div>
                                <Button
                                    className="pool-card-item-btn"
                                    variant="contained"
                                    fullWidth={true}
                                    onClick={() => {
                                        if (isPendingTxn(pendingTransactions, "claim_" + token)) return;
                                        onClaim(token);
                                    }}
                                >
                                    {txnButtonText(pendingTransactions, "claim_" + token, "Claim MNT")}
                                </Button>
                                {/* <div
                                    className="modal-panel-btn"
                                    onClick={() => {
                                        if (isPendingTxn(pendingTransactions, "claim_" + token)) return;
                                        onClaim(token);
                                    }}
                                >
                                    <p>{txnButtonText(pendingTransactions, "claim_" + token, "Claim MNT")}</p>
                                </div> */}
                            </div>
                        )}
                    </>
                )}
            </div>
            <Modal open={depositOpen} onClose={handleDepositClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="pool-modal-box">
                    <div className="modal-header">
                        <h1 className="modal-title">Deposit {tokenName}</h1>
                        <img className="close-icon modal-close" alt="" src={CloseIcon} width={28} height={28} onClick={handleDepositClose} />
                    </div>
                    <div className="modal-body">
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">LP Balance</p>
                            <p className="modal-data-row-value">
                                {formatValue(lp_balance)} {lpTokenName}
                            </p>
                        </div>
                        <div className="modal-data-row ">
                            <p className="modal-data-row-name"> Balance</p>
                            <p className="modal-data-row-value">
                                {formatValue(balance)} {tokenName}
                            </p>
                        </div>
                        <OutlinedInput
                            type="number"
                            placeholder="Amount"
                            className="modal-input"
                            value={depositQuantity}
                            onChange={e => setPositiveDepositQuantity(e.target.value)}
                            labelWidth={0}
                            startAdornment={
                                <InputAdornment position="start">
                                    <div onClick={setDepositMax} className="modal-input-btn">
                                        <p>MAX</p>
                                    </div>
                                </InputAdornment>
                            }
                        />
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">
                                Fee
                                <LightTooltip title="Applicable when liquidity ratio of token > 1" placement="top-start" arrow>
                                    <img className="tooltip-icon" alt="" src={InfoIcon} width={12} height={12} />
                                </LightTooltip>
                            </p>
                            <p className="modal-data-row-value">
                                {formatValueDigits(depositFees, 4)} {tokenName}
                            </p>
                        </div>
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">New Liquidity</p>
                            <p className="modal-data-row-value">
                                {formatValue((Number(lp_balance) + Number(depositQuantity) - Number(depositFees)).toString())} {tokenName}
                            </p>
                        </div>
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">New Pool Share</p>
                            <p className="modal-data-row-value">{getDepositPoolShare()}%</p>
                        </div>
                        {hasAllowance(token) ? (
                            <>
                                <div
                                    className="modal-panel-btn"
                                    onClick={() => {
                                        if (isPendingTxn(pendingTransactions, "deposit_" + token)) return;
                                        onDeposit(token, false);
                                    }}
                                >
                                    <p>{txnButtonText(pendingTransactions, "deposit_" + token, "Deposit")}</p>
                                </div>
                                <div
                                    className="modal-panel-btn"
                                    onClick={() => {
                                        if (isPendingTxn(pendingTransactions, "deposit_" + token)) return;
                                        onDeposit(token, true);
                                    }}
                                >
                                    <p>{txnButtonText(pendingTransactions, "deposit_" + token, "Deposit & Stake")}</p>
                                </div>
                            </>
                        ) : (
                            <div
                                className="modal-panel-btn"
                                onClick={() => {
                                    if (isPendingTxn(pendingTransactions, "approve_" + token)) return;
                                    onSeekApproval(token);
                                }}
                            >
                                <p>{txnButtonText(pendingTransactions, "approve_" + token, "Approve")}</p>
                            </div>
                        )}
                    </div>
                </Box>
            </Modal>

            <Modal open={withdrawOpen} onClose={handleWithdrawClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="pool-modal-box">
                    <div className="modal-header">
                        <h1 className="modal-title">Withdraw {tokenName}</h1>
                        <img className="close-icon modal-close" alt="" src={CloseIcon} width={28} height={28} onClick={handleWithdrawClose} />
                    </div>
                    <div className="modal-body">
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">LP Balance</p>
                            <p className="modal-data-row-value">
                                {formatValue(lp_balance)} {lpTokenName}
                            </p>
                        </div>
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">
                                {" "}
                                Staked
                                <LightTooltip title="LP tokens staked into the protocol. You must unstake these before they can be withdrawn" placement="top-start" arrow>
                                    <img className="tooltip-icon" alt="" src={InfoIcon} width={12} height={12} />
                                </LightTooltip>
                            </p>
                            <p className="modal-data-row-value">
                                {formatValue(staked_lp_balance)} {lpTokenName}
                            </p>
                        </div>
                        <OutlinedInput
                            type="number"
                            placeholder="Amount"
                            className="modal-input"
                            value={withdrawQuantity}
                            onChange={e => setPositiveWithdrawQuantity(e.target.value)}
                            labelWidth={0}
                            startAdornment={
                                <InputAdornment position="start">
                                    <div onClick={setWithdrawMax} className="modal-input-btn">
                                        <p>MAX</p>
                                    </div>
                                </InputAdornment>
                            }
                        />
                        {/* <div className="modal-data-row">
                            <p className="modal-data-row-name">Amount withdrawing</p>
                            <p className="modal-data-row-value">
                                {withdrawQuantity} {tokenName}
                            </p>
                        </div> */}
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">
                                Fee
                                <LightTooltip title="Applicable when liquidity ratio of token < 1" placement="top-start" arrow>
                                    <img className="tooltip-icon" alt="" src={InfoIcon} width={12} height={12} />
                                </LightTooltip>
                            </p>
                            <p className="modal-data-row-value">
                                {formatValueDigits(withdrawFees, 4)} {tokenName}
                            </p>
                        </div>
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">Remaining Liquidity</p>
                            <p className="modal-data-row-value">
                                {formatValue((Number(lp_balance) - Number(withdrawQuantity)).toString())} {tokenName}
                            </p>
                        </div>
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">New Pool Share</p>
                            <p className="modal-data-row-value">{getWithdrawPoolShare()}%</p>
                        </div>
                        {hasAllowance(lp_string) ? (
                            <div
                                className="modal-panel-btn"
                                onClick={() => {
                                    if (isPendingTxn(pendingTransactions, "withdraw_" + token)) return;
                                    onWithdraw(token);
                                }}
                            >
                                <p>{txnButtonText(pendingTransactions, "withdraw_" + token, "Withdraw")}</p>
                            </div>
                        ) : (
                            <div
                                className="modal-panel-btn"
                                onClick={() => {
                                    if (isPendingTxn(pendingTransactions, "approve_" + lp_string)) return;
                                    onSeekApproval(lp_string);
                                }}
                            >
                                <p>{txnButtonText(pendingTransactions, "approve_" + lp_string, "Approve")}</p>
                            </div>
                        )}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
