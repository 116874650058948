import KeeperImg from "../assets/tokens/MNT.png";
import TroveImg from "../assets/tokens/TROVE.svg";
import USDCImg from "../assets/tokens/USDC.svg";
import USDTImg from "../assets/tokens/USDT.svg";
import DAIImg from "../assets/tokens/DAI.svg";
import MATICImg from "../assets/tokens/MATIC.svg";

function toUrl(tokenPath: string): string {
    const host = window.location.origin;
    return `${host}/${tokenPath}`;
}

export function getTokenUrl(name: string) {
    if (name === "mnt") {
        return toUrl(KeeperImg);
    } else if (name === "usdc") {
        return toUrl(USDCImg);
    } else if (name === "usdt") {
        return toUrl(USDTImg);
    } else if (name === "dai") {
        return toUrl(DAIImg);
    }

    throw Error(`Token url doesn't support: ${name}`);
}

export function getTokenSVG(name: string) {
    if (name === "usdc") {
        return USDCImg;
    } else if (name === "usdt") {
        return USDTImg;
    } else if (name === "dai") {
        return DAIImg;
    } else if (name === "matic") {
        return MATICImg;
    }
}
