import { Networks } from "./blockchain";

const MATIC_MAINNET = {
    MASTER_MANTIS_ADDRESS: "0x6d4Fb92581646aA18547bd8cAA952ec8aeF7B13F",
    POOL_ADDRESS: "0xa3481153C59D6070487C4c9eABBbf9E61B9fb1f4",
    MNT_ADDRESS: "0xB3B7C41c5149631DC170981581C6C3Da13990fef",
    VEMNT_ADDRESS: "0xF17acA217622f64EEeD6b185fFA579F85914943C",
    ASSET_USDC_ADDRESS: "0xa636D3c0b9f0965d97f7d4832BF2BAd49f72e89f",
    ASSET_USDT_ADDRESS: "0xDF4969565576a890e925eeD7F25808496eFeC2e9",
    ASSET_DAI_ADDRESS: "0xbEd95e278B106b997FBfd83cFd7997aA0D42BEB2",
    USDC_ADDRESS: "0xbd9E94b787A2b0835a290B459F94F26738edf852",
    USDT_ADDRESS: "0xddfcdA702205e7E65229ede5D50c760FCC60cf1e",
    DAI_ADDRESS: "0x3C0d2375d317092F530caFb440337b4E88163f29",
    MARKETPLACE_ADDRESS: "",
};

const ZK_MAINNET = {
    MASTER_MANTIS_ADDRESS: "0x10CA56461E7fff96785e1BdDA3dA3361b1c135C9",
    POOL_ADDRESS: "0x9A368Ea1A23198E718c99fE316A4f913f2867e12",
    MNT_ADDRESS: "0xa338591D89305d1Dd85df5e6454A3aa2d17342A4",
    VEMNT_ADDRESS: "0x21e0462986d095351213298b7DE1D96e0eC24A84",
    ASSET_USDC_ADDRESS: "0xA50F9053bF192b49Ec7244a5566ef5B08cAaa4E3",
    ASSET_USDT_ADDRESS: "0x22F3157B3571CBA039219aAA1fc0899668022347",
    ASSET_DAI_ADDRESS: "0x82Ab73E225095c53C83DbFe612f19E1cd331E62E",
    USDC_ADDRESS: "0xc044A3cB58D768DF16EB4d0e1aB49C52006dDa26",
    USDT_ADDRESS: "0x59CAcD93925766283Fa660c554a6425a80d23083",
    DAI_ADDRESS: "0x361e0B6043EBdD2Bb551D6Ebc8b24486906A3116",
    MARKETPLACE_ADDRESS: "",
};

export const getAddresses = (networkID: number) => {
    if (networkID === Networks.MATIC) return MATIC_MAINNET;
    if (networkID === Networks.ZK) return ZK_MAINNET;

    throw Error("Network don't support");
};
