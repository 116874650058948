import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import Social from "./social";
import SwapIcon from "../../../assets/icons/arrows-rotate-solid.svg";
import PoolIcon from "../../../assets/icons/coins-solid.svg";
import FaucetIcon from "../../../assets/icons/circle-dollar-to-slot.svg";
import LeaderboardIcon from "../../../assets/icons/chart.svg";
import UserIcon from "../../../assets/icons/user.svg";
import MNTLogo from "../../../assets/icons/logos/header_mix.svg";
import { trim, shorten, shortenWallet } from "../../../helpers";
import { useAddress } from "../../../hooks";
import { Link } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import "./drawer-content.scss";
import classnames from "classnames";

function NavContent() {
    const [isActive] = useState();
    const address = useAddress();

    const checkPage = useCallback((location: any, page: string): boolean => {
        const currentPath = location.pathname.replace("/", "");
        if (currentPath.indexOf("swap") >= 0 && page === "swap") {
            return true;
        }
        if (currentPath.indexOf("pool") >= 0 && page === "pool") {
            return true;
        }
        if (currentPath.indexOf("vemnt") >= 0 && page === "vemnt") {
            return true;
        }
        if (currentPath.indexOf("faucet") >= 0 && page === "faucet") {
            return true;
        }
        if (currentPath.indexOf("leaderboard") >= 0 && page === "leaderboard") {
            return true;
        }
        return false;
    }, []);

    return (
        <div className="dapp-sidebar">
            <div className="branding-header">
                <Link href="https://mantissa.finance" target="_blank">
                    <img alt="Mantis" src={MNTLogo} height="96" style={{ marginTop: "30px" }} />
                </Link>
            </div>

            <div className="dapp-menu-links">
                <div className="dapp-nav">
                    <Link
                        component={NavLink}
                        to="/swap"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "swap");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={SwapIcon} />
                            <p>Swap</p>
                        </div>
                    </Link>
                    <Link
                        component={NavLink}
                        to="/pool"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "pool");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={PoolIcon} />
                            <p>Pool</p>
                        </div>
                    </Link>
                    {/* <Link
                        component={NavLink}
                        to="/vemnt"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "vemnt");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={BondIcon} />
                            <p>veMNT</p>
                        </div>
                    </Link> */}
                    <Link
                        component={NavLink}
                        to="/faucet"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "faucet");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={FaucetIcon} />
                            <p>Faucet</p>
                        </div>
                    </Link>
                    <Link
                        component={NavLink}
                        to="/leaderboard"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "leaderboard");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <img alt="" src={LeaderboardIcon} />
                            <p>Leaderboard</p>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="dapp-menu-doc-link"></div>
            {address && (
                <div className="wallet-row">
                    <Link href={`https://mumbai.polygonscan.com/address/${address}`} target="_blank">
                        <img alt="" src={UserIcon} />
                        <p>{shortenWallet(address)}</p>
                    </Link>
                </div>
            )}
            {/* <Social /> */}
        </div>
    );
}

export default NavContent;
