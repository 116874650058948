import "./vemnt.scss";
import { useWeb3Context } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "src/store/slices/state.interface";
import { changeApproval, changeDeposit, claim } from "src/store/slices/vemnt-thunk";
import { useState, useCallback, useEffect } from "react";
import { Grid, InputAdornment, OutlinedInput, Zoom, Box, Modal } from "@material-ui/core";
import { trim } from "../../helpers";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { Skeleton } from "@material-ui/lab";
import { ethers } from "ethers";
import { getAddresses, Networks } from "src/constants";
import { StableReserveContract, VeMNTTokenContract } from "src/abi";
import { fetchAccountSuccess } from "src/store/slices/account-slice";
import TokenTiltedImage from "../../assets/icons/logos/token-tilted.png";

function VeMNT() {
    const dispatch = useDispatch();
    const addresses = getAddresses(Networks.MATIC);
    const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();

    const [stakeQuantity, setStakeQuantity] = useState<string>("");
    const [unstakeQuantity, setUnstakeQuantity] = useState<string>("");

    const [stakeOpen, setStakeOpen] = useState<boolean>(false);
    const [unstakeOpen, setUnstakeOpen] = useState<boolean>(false);

    const [mineRate, setMineRate] = useState<number>(0);

    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
    const mntBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.mnt;
    });
    const vemntBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.vemnt;
    });
    const mntStaked = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.staked_mnt;
    });
    const vemntClaimable = useSelector<IReduxState, string>(state => {
        return state.account.claim && state.account.claim.vemnt;
    });
    const mntAllowance = useSelector<IReduxState, number>(state => {
        return state.account.allowance_vemnt && state.account.allowance_vemnt.mnt;
    });
    const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
        return state.pendingTransactions;
    });

    const setStakeMax = () => {
        setStakeQuantity(mntBalance);
    };

    const setPositiveStakeQuantity = (value: string) => {
        Number(value) > 0 ? setStakeQuantity(value) : setStakeQuantity("");
    };

    const setPositiveUnstakeQuantity = (value: string) => {
        Number(value) > 0 ? setUnstakeQuantity(value) : setUnstakeQuantity("");
    };

    const setUnstakeMax = () => {
        setUnstakeQuantity(mntStaked);
    };

    const onSeekApproval = async (token: string) => {
        if (await checkWrongNetwork()) return;

        await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
    };

    const onChangeDeposit = async (action: string) => {
        if (await checkWrongNetwork()) return;
        const quantity = action === "deposit" ? stakeQuantity : unstakeQuantity;
        await dispatch(changeDeposit({ address, action, value: quantity, provider, networkID: chainID }));
        action === "deposit" ? setStakeQuantity("") : setUnstakeQuantity("");
    };

    const onClaim = async (action: string) => {
        if (await checkWrongNetwork()) return;
        await dispatch(claim({ address, action, provider, networkID: chainID }));
    };

    const hasAllowance = useCallback(
        token => {
            if (token === "mnt") return mntAllowance > 0;
            return 0;
        },
        [mntAllowance],
    );

    useEffect(() => {
        const getBalances = async () => {
            if (address) {
                const mntContract = new ethers.Contract(addresses.MNT_ADDRESS, StableReserveContract, provider);
                const vemntContract = new ethers.Contract(addresses.VEMNT_ADDRESS, VeMNTTokenContract, provider);
                const mnt_balance = await mntContract.balanceOf(address);
                const vemnt_balance = await vemntContract.balanceOf(address);
                const vemnt_claimable = await vemntContract.claimable(address);
                dispatch(
                    fetchAccountSuccess({
                        balances: {
                            mnt: ethers.utils.formatUnits(mnt_balance, "ether"),
                            vemnt: ethers.utils.formatUnits(vemnt_balance, "ether"),
                        },
                        claim: {
                            vemnt: ethers.utils.formatUnits(vemnt_claimable, "ether"),
                        },
                    }),
                );

                const mnt_allowance = await mntContract.allowance(address, addresses.VEMNT_ADDRESS);
                const userMNTData = await vemntContract.userData(address);
                const stakedMNT = userMNTData.amount;
                const vemntRate = Number(ethers.utils.formatUnits(userMNTData.veMntRate, "ether"));
                setMineRate(vemntRate * 3600);
                dispatch(
                    fetchAccountSuccess({
                        balances: {
                            staked_mnt: ethers.utils.formatUnits(stakedMNT, "ether"),
                        },
                        allowance_vemnt: {
                            mnt: mnt_allowance,
                        },
                    }),
                );
            }
        };
        getBalances();
    }, []);

    const handleStakeOpen = () => setStakeOpen(true);
    const handleStakeClose = () => setStakeOpen(false);
    const handleUnstakeOpen = () => setUnstakeOpen(true);
    const handleUnstakeClose = () => setUnstakeOpen(false);

    return (
        <div className="stake-view">
            <Zoom in={true}>
                <div className="stake-card mb-3">
                    <Grid className="stake-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="stake-card-header">
                                <p className="stake-card-header-title">veMNT</p>
                            </div>
                        </Grid>

                        <div className="stake-card-area">
                            {!address && (
                                <div className="stake-card-wallet-notification">
                                    <div className="stake-card-wallet-connect-btn" onClick={connect}>
                                        <p>Connect Wallet</p>
                                    </div>
                                    <p className="stake-card-wallet-desc-text">Connect your wallet!</p>
                                </div>
                            )}
                            {address && (
                                <div>
                                    <div className="stake-card-action-area">
                                        <div className="stake-card-action-row">
                                            <div className="stake-card-tab-panel-full">
                                                <img src={TokenTiltedImage} width={350} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="stake-user-data">
                                        <div className="data-row">
                                            <p className="data-row-name">veMNT Claimable</p>
                                            <p className="data-row-value">
                                                {isAppLoading || !vemntClaimable ? <Skeleton width="80px" /> : <>{trim(Number(vemntClaimable), 4)} veMNT</>}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="stake-card-action-area">
                                        <div className="stake-card-action-row">
                                            <div className="stake-card-tab-panel-full">
                                                <div
                                                    className="stake-card-tab-panel-btn secondary"
                                                    onClick={() => {
                                                        if (isPendingTxn(pendingTransactions, "vemnt_claim")) return;
                                                        onClaim("vemnt");
                                                    }}
                                                >
                                                    <p>{txnButtonText(pendingTransactions, "vemnt_claim", "Claim veMNT")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="stake-user-data">
                                        <div className="data-row">
                                            <p className="data-row-name">MNT Balance</p>
                                            <p className="data-row-value">{isAppLoading || !mntBalance ? <Skeleton width="80px" /> : <>{trim(Number(mntBalance), 4)} MNT</>}</p>
                                        </div>
                                        <div className="data-row">
                                            <p className="data-row-name">MNT Staked</p>
                                            <p className="data-row-value">{isAppLoading || !mntStaked ? <Skeleton width="80px" /> : <>{trim(Number(mntStaked), 4)} MNT</>}</p>
                                        </div>
                                        <div className="data-row">
                                            <p className="data-row-name">veMNT Balance</p>
                                            <p className="data-row-value">
                                                {isAppLoading || !vemntBalance ? <Skeleton width="80px" /> : <>{trim(Number(vemntBalance), 4)} veMNT</>}
                                            </p>
                                        </div>
                                        <div className="data-row">
                                            <p className="data-row-name">veMNT Mine Rate</p>
                                            <p className="data-row-value">
                                                {isAppLoading || !mntStaked ? <Skeleton width="80px" /> : <>{trim(Number(mntStaked) * mineRate, 4)} veMNT/hr/MNT</>}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="stake-card-action-area">
                                        <div className="stake-card-action-row">
                                            <div className="stake-card-tab-panel-full">
                                                <div className="stake-card-tab-panel-btn primary" onClick={handleStakeOpen}>
                                                    <p>Stake</p>
                                                </div>
                                                <div className="stake-card-tab-panel-btn ml-2" onClick={handleUnstakeOpen}>
                                                    <p>Unstake</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grid>
                </div>
            </Zoom>
            <Modal open={stakeOpen} onClose={handleStakeClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="modal-box">
                    <div className="modal-header">
                        <h1 className="modal-title">Stake MNT</h1>
                        <span className="modal-close" onClick={handleStakeClose}>
                            X
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">Stakable</p>
                            <p className="modal-data-row-value">{trim(Number(mntBalance), 2)} MNT</p>
                        </div>
                        <OutlinedInput
                            type="number"
                            placeholder="Amount"
                            className="modal-input"
                            value={stakeQuantity}
                            onChange={e => setPositiveStakeQuantity(e.target.value)}
                            labelWidth={0}
                            startAdornment={
                                <InputAdornment position="start">
                                    <div onClick={setStakeMax} className="modal-input-btn">
                                        <p>MAX</p>
                                    </div>
                                </InputAdornment>
                            }
                        />
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">Total Staked</p>
                            <p className="modal-data-row-value">{trim(Number(mntStaked) + Number(stakeQuantity), 2)} MNT</p>
                        </div>
                        {hasAllowance("mnt") ? (
                            <>
                                <div
                                    className="modal-panel-btn"
                                    onClick={() => {
                                        if (isPendingTxn(pendingTransactions, "mnt_deposit")) return;
                                        onChangeDeposit("deposit");
                                    }}
                                >
                                    <p>{txnButtonText(pendingTransactions, "mnt_deposit", "Deposit")}</p>
                                </div>
                            </>
                        ) : (
                            <div
                                className="modal-panel-btn"
                                onClick={() => {
                                    if (isPendingTxn(pendingTransactions, "approve_mnt")) return;
                                    onSeekApproval("mnt");
                                }}
                            >
                                <p>{txnButtonText(pendingTransactions, "approve_mnt", "Approve MNT")}</p>
                            </div>
                        )}
                    </div>
                </Box>
            </Modal>

            <Modal open={unstakeOpen} onClose={handleUnstakeClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box className="modal-box">
                    <div className="modal-header">
                        <h1 className="modal-title"> Unstake MNT</h1>
                        <span className="modal-close" onClick={handleUnstakeClose}>
                            X
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">Staked</p>
                            <p className="modal-data-row-value">{trim(Number(mntStaked), 2)} MNT</p>
                        </div>
                        <OutlinedInput
                            type="number"
                            placeholder="Amount"
                            className="modal-input"
                            value={unstakeQuantity}
                            onChange={e => setPositiveUnstakeQuantity(e.target.value)}
                            labelWidth={0}
                            startAdornment={
                                <InputAdornment position="start">
                                    <div onClick={setUnstakeMax} className="modal-input-btn">
                                        <p>MAX</p>
                                    </div>
                                </InputAdornment>
                            }
                        />
                        <div className="modal-data-row">
                            <p className="modal-data-row-name">Total Staked</p>
                            <p className="modal-data-row-value">{trim(Number(mntStaked) - Number(unstakeQuantity), 2)} MNT</p>
                        </div>
                        <div
                            className="modal-panel-btn"
                            onClick={() => {
                                if (isPendingTxn(pendingTransactions, "mnt_withdraw")) return;
                                onChangeDeposit("withdraw");
                            }}
                        >
                            <p>{txnButtonText(pendingTransactions, "mnt_withdraw", "Withdraw")}</p>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
export default VeMNT;
