import "./faucet.scss";
import { useWeb3Context } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "src/store/slices/state.interface";
import { changeApproval } from "src/store/slices/pool-thunk";
import { useState } from "react";
import { Grid, Zoom } from "@material-ui/core";
import { IPendingTxn } from "../../store/slices/pending-txns-slice";
import { error, success, warning } from "../../store/slices/messages-slice";
import { getAddresses } from "src/constants";
import { apiPostRequest } from "src/helpers/api-request";
import { addTokenToWallet } from "src/components/Header/time-menu";
import CheckIcon from "../../assets/icons/check-circle.svg";
import { getTokenSVG } from "src/helpers";

function Faucet() {
    const dispatch = useDispatch();
    const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();
    const addresses = getAddresses(chainID);

    const [fundsRequested, setFundsRequested] = useState<boolean>(false);

    const postUrl = "https://api.mantissa.finance/api/testnet/distribute/";

    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
    const usdcBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.usdc;
    });
    const usdtBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.usdt;
    });
    const daiBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.dai;
    });
    const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
        return state.pendingTransactions;
    });

    const onSeekApproval = async (token: string) => {
        if (await checkWrongNetwork()) return;

        await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
    };

    function canRequestFunds() {
        return !fundsRequested && Number(usdcBalance) < 1000 && Number(usdtBalance) < 1000 && Number(daiBalance) < 1000;
    }

    const onFundRequest = async () => {
        if (await checkWrongNetwork()) return;
        if (fundsRequested) {
            dispatch(warning({ text: "Request Already Made" }));
        } else {
            setFundsRequested(true);
            try {
                const response = await apiPostRequest(postUrl, { recipient: address });
                if (response.successful) {
                    dispatch(success({ text: "Request successful. Funds will arrive shortly" }));
                } else {
                    dispatch(error({ text: response.reason }));
                    setFundsRequested(false);
                }
            } catch {
                setFundsRequested(false);
                dispatch(error({ text: "Unable to request. Try Again Later" }));
            }
        }
    };

    return (
        <div className="faucet-view">
            <Zoom in={true}>
                <div className="faucet-card mb-3">
                    <Grid className="faucet-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="faucet-card-header">
                                <p className="faucet-card-header-title">Faucet</p>
                            </div>
                        </Grid>

                        <div className="faucet-card-area">
                            {!address && (
                                <div className="faucet-card-wallet-notification">
                                    <div className="faucet-card-wallet-connect-btn" onClick={connect}>
                                        <p>Connect Wallet</p>
                                    </div>
                                    <p className="faucet-card-wallet-desc-text">Connect your wallet to receive testnet tokens!</p>
                                </div>
                            )}
                            {address && (
                                <div>
                                    <div className="faucet-card-action-area bg-primary">
                                        <p>
                                            The Faucet distributes <b>MATIC</b> and <b>MantisSwap Testnet specific USDC, USDT, DAI</b> tokens on Polygon Mumbai network. The faucet
                                            can only be used once per wallet address.
                                        </p>
                                    </div>
                                    <div className="faucet-card-action-area bg-secondary">
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12}>
                                                <div className="info-data-row">
                                                    <p className="info-data-row-name">YOUR ADDRESS</p>
                                                    <p className="info-data-row-value">{address}</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div className="faucet-card-action-row">
                                                    <div className="faucet-card-tab-panel">
                                                        {canRequestFunds() ? (
                                                            <div className="faucet-card-tab-panel-btn" onClick={onFundRequest}>
                                                                <p>Get Test Tokens</p>
                                                            </div>
                                                        ) : (
                                                            <div className="faucet-card-tab-panel-btn disabled">
                                                                <img className="btn-icon" alt="" src={CheckIcon} width={16} height={16} />
                                                                <p>Test Tokens Sent</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <p className="help-text">
                                            The following tokens and quantities will be sent to your wallet. To see tokens in your wallet, click Add to Wallet button corresponding
                                            to each token.
                                        </p>
                                        {/* <p>Address: {address}</p>
                                        <div className="faucet-card-action-row">
                                            <div className="faucet-card-tab-panel">
                                                {canRequestFunds() && (
                                                    <div className="faucet-card-tab-panel-btn" onClick={onFundRequest}>
                                                        <p>Request Tokens</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="faucet-token-data">
                                        <div className="data-row">
                                            <p className="data-row-name">
                                                <img src={getTokenSVG("matic")} width={24} height={24} />
                                                <span className="data-row-name-title">tMATIC</span>
                                            </p>
                                            <p className="data-row-value">0.2</p>
                                            <p className="data-row-link">Already Included</p>
                                        </div>
                                        <div className="data-row">
                                            <p className="data-row-name">
                                                <img src={getTokenSVG("usdc")} width={24} height={24} />
                                                <span className="data-row-name-title">USDC</span>
                                            </p>
                                            <p className="data-row-value">300,000</p>
                                            <p className="data-row-link">
                                                <button onClick={addTokenToWallet("USDC", addresses.USDC_ADDRESS)} className="data-row-link-btn">
                                                    <p>Add to Wallet</p>
                                                </button>
                                            </p>
                                        </div>
                                        <div className="data-row">
                                            <p className="data-row-name">
                                                <img src={getTokenSVG("usdt")} width={24} height={24} />
                                                <span className="data-row-name-title">USDT</span>
                                            </p>
                                            <p className="data-row-value">300,000</p>
                                            <p className="data-row-link">
                                                <button onClick={addTokenToWallet("USDT", addresses.USDT_ADDRESS)} className="data-row-link-btn">
                                                    <p>Add to Wallet</p>
                                                </button>
                                            </p>
                                        </div>
                                        <div className="data-row">
                                            <p className="data-row-name">
                                                <img src={getTokenSVG("dai")} width={24} height={24} />
                                                <span className="data-row-name-title">DAI</span>
                                            </p>
                                            <p className="data-row-value"> 300,000</p>
                                            <p className="data-row-link">
                                                <button onClick={addTokenToWallet("DAI", addresses.DAI_ADDRESS)} className="data-row-link-btn">
                                                    <p>Add to Wallet</p>
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Grid>
                </div>
            </Zoom>
        </div>
    );
}
export default Faucet;
