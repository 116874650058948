import MaticIcon from "../assets/icons/networks/matic-icon.png";

export const TOKEN_DECIMALS = 18;

export enum Networks {
    MATIC = 80001,
    ZK = 1402,
}

export const AVAILABLE_CHAINS = [Networks.MATIC, Networks.ZK];

export const DEFAULT_NETWORK = Networks.MATIC;

export const NetworksInfo = {
    [Networks.MATIC]: {
        chainId: "0x13881",
        chainName: "Polygon Testnet",
        shortName: "Mumbai",
        rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
        nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
        },
        img: MaticIcon,
    },
    [Networks.ZK]: {
        chainId: "0x57A",
        chainName: "Polygon zkEVM Testnet",
        shortName: "zkEVM",
        rpcUrls: ["https://public.zkevm-test.net:2083"],
        blockExplorerUrls: ["https://public.zkevm-test.net:8443"],
        nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18,
        },
        img: MaticIcon,
    },
};
