import { ethers } from "ethers";
import { getAddresses } from "../../constants";
import { StableReserveContract, VeMNTTokenContract } from "../../abi";
import { clearPendingTxn, fetchPendingTxns } from "./pending-txns-slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances } from "./account-slice";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { Networks } from "../../constants/blockchain";
import { warning, success, info, error } from "./messages-slice";
import { messages } from "../../constants/messages";
import { getGasPrice } from "../../helpers/get-gas-price";

interface IChangeApproval {
    token: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const changeApproval = createAsyncThunk("vemnt/changeApproval", async ({ token, provider, address, networkID }: IChangeApproval, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);

    const signer = provider.getSigner();
    const mntContract = new ethers.Contract(addresses.MNT_ADDRESS, StableReserveContract, signer);

    let approveTx;
    try {
        const gasPrice = await getGasPrice(provider);

        if (token === "mnt") {
            approveTx = await mntContract.approve(addresses.VEMNT_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
        }

        const text = "Approve " + token.toUpperCase();
        const pendingTxnType = "approve_" + token;

        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await approveTx.wait();
    } catch (err: any) {
        dispatch(error({ text: messages.something_wrong, error: err.message }));
        return;
    } finally {
        if (approveTx) {
            dispatch(clearPendingTxn(approveTx.hash));
        }
    }

    const mntAllowance = await mntContract.allowance(address, addresses.VEMNT_ADDRESS);

    return dispatch(
        fetchAccountSuccess({
            allowance_vemnt: {
                mnt: Number(mntAllowance),
            },
        }),
    );
});

interface IChangeDeposit {
    action: string;
    value: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const changeDeposit = createAsyncThunk("vemnt/changeDeposit", async ({ action, value, provider, address, networkID }: IChangeDeposit, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const vemntContract = new ethers.Contract(addresses.VEMNT_ADDRESS, VeMNTTokenContract, signer);

    let stakeTx;

    try {
        const gasPrice = await getGasPrice(provider);
        const amount = ethers.utils.parseUnits(value, "ether");
        if (action === "deposit") {
            stakeTx = await vemntContract.deposit(amount, { gasPrice });
        } else if (action === "withdraw") {
            stakeTx = await vemntContract.withdraw(amount, { gasPrice });
        } else {
            dispatch(warning({ text: "Wrong token. Fix it" }));
            return;
        }
        const text = "MNT  " + action.toUpperCase();
        const pendingTxnType = "mnt_" + action;
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: action, type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await stakeTx.wait();
    } catch (err: any) {
        if (err.code === -32603 && err.message.indexOf("ds-math-sub-underflow") >= 0) {
            dispatch(error({ text: "You may be trying to deposit more than your balance! Error code: 32603. Message: ds-math-sub-underflow", error: err }));
        } else {
            dispatch(error({ text: messages.something_wrong, error: err }));
        }
        return;
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(getBalances({ address, tokens: ["mnt", "vemnt"], networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
});

interface IClaim {
    action: string;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    address: string;
    networkID: Networks;
}

export const claim = createAsyncThunk("vemnt/claim", async ({ action, provider, address, networkID }: IClaim, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const vemntContract = new ethers.Contract(addresses.VEMNT_ADDRESS, VeMNTTokenContract, signer);

    let stakeTx;

    try {
        const gasPrice = await getGasPrice(provider);
        if (action === "vemnt") {
            stakeTx = await vemntContract.claim({ gasPrice });
        } else {
            dispatch(warning({ text: "Wrong token. Fix it" }));
            return;
        }
        const text = "veMNT  " + action.toUpperCase();
        const pendingTxnType = action + "_claim";
        dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: action, type: pendingTxnType }));
        dispatch(success({ text: messages.tx_successfully_send }));
        await stakeTx.wait();
    } catch (err: any) {
        if (err.code === -32603 && err.message.indexOf("ds-math-sub-underflow") >= 0) {
            dispatch(error({ text: "You may be trying to deposit more than your balance! Error code: 32603. Message: ds-math-sub-underflow", error: err }));
        } else {
            dispatch(error({ text: messages.something_wrong, error: err }));
        }
        return;
    } finally {
        if (stakeTx) {
            dispatch(clearPendingTxn(stakeTx.hash));
        }
    }
    dispatch(getBalances({ address, tokens: ["vemnt"], networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
});
