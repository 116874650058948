import "./notfound.scss";

function NotFound() {
    return (
        <div className="not-found-page">
            <p>Not Found</p>
        </div>
    );
}

export default NotFound;
